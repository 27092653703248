// @flow

import type { ToolID } from 'hooks/usePreferences/allTools';
import type { ColorMapPreset } from 'react-vtk-js';

/** 
 * Do not edit the JSON manually. Use the `generates colormaps.json` unit test in
   frontend/src/domains/viewer/ViewportDre/utils/useColorMapConfig.test.js to generate the color map
   params for all VTK color map presets 
  */
// $FlowFixMe[untyped-import]
import ColorMapJson from './utils/colorMaps';

export const MOUSE_BUTTON: { LEFT: 1, MIDDLE: 2, RIGHT: 3, WHEEL: 0 } = {
  LEFT: 1,
  MIDDLE: 2,
  RIGHT: 3,
  WHEEL: 0,
};
export const mouseButtonToVTKButton = (button: number): number => {
  return button + 1;
};
export type MouseButtonValue = $Values<typeof MOUSE_BUTTON>;

// 45 degrees
export const MANUAL_LINKED_SCROLL_TOLERANCE_RADIANS: number = 0.75;

// Fairly permissive but catches disc axials
export const AUTOMATIC_LINKED_SCROLL_TOLERANCE_RADIANS: number = 0.5;

// Fairly permissive but catches disc axials
export const AUTOMATIC_LINKED_ZOOM_TOLERANCE_RADIANS: number = 0.5;

export const IMAGE_COLOR_MAP_PRESETS = {
  DEFAULT: 'Grayscale',
  INVERTED: 'X Ray',
};

export const BLANK_SLICE: number = Infinity;

export const PROGRESSIVE_RENDER_THRESHOLD: number = 300;

export const SLICE_AXIS = {
  TWO_D_DRE: 'kSlice',
  SAGITTAL_DRE: 'xSlice',
  CORONAL_DRE: 'ySlice',
  AXIAL_DRE: 'zSlice',
  THREE_D_DRE: 'kSlice',
  THREE_D_MIP: 'kSlice',
};

// corresponds to the constant above
export const SLICE_AXIS_INDEX = {
  CORONAL_DRE: 1,
  AXIAL_DRE: 2,
  TWO_D_DRE: 2,
  SAGITTAL_DRE: 0,
};
// these tools require complete orientation and spatial information to function
// we limit them on certain types of imaging that do not have this info
// (MPRS, Secondary Capture, images missing spacing information)
export const RESTRICTED_FUNCTIONALITY_TOOLS: Array<ToolID> = [
  'LENGTH',
  'ANGLE',
  'COBB_ANGLE',
  'ELLIPSE',
  'CIRCLE',
  'RECTANGLE',
  'CONTOUR',
  'ARROW',
  'VERTEBRAE_LABEL',
];

export const SOP_CLASSES_TO_TOOLS_DISABLED_MAP: { [string]: Array<ToolID> } = {
  // Ultrasound Image IOD
  '1.2.840.10008.5.1.4.1.1.6.1': ['VERTEBRAE_LABEL', 'COBB_ANGLE', 'ANGLE', 'ELLIPSE'],
  // Ultrasound Multi-frame Image IOD
  '1.2.840.10008.5.1.4.1.1.3.1': ['VERTEBRAE_LABEL', 'COBB_ANGLE', 'ANGLE', 'ELLIPSE'],
  // Secondary Capture SOP Class UID
  '1.2.840.10008.5.1.4.1.1.7': [...RESTRICTED_FUNCTIONALITY_TOOLS],
};

export const VIEW_TYPE_TO_TOOLS_DISABLED_MAP: { [string]: Array<ToolID> } = {
  AXIAL_DRE: [...RESTRICTED_FUNCTIONALITY_TOOLS],
  CORONAL_DRE: [...RESTRICTED_FUNCTIONALITY_TOOLS],
  SAGITTAL_DRE: [...RESTRICTED_FUNCTIONALITY_TOOLS],
  THREE_D_DRE: [...RESTRICTED_FUNCTIONALITY_TOOLS],
  THREE_D_MIP: [...RESTRICTED_FUNCTIONALITY_TOOLS],
};

export const LIMITED_VIEWING_SOP_CLASSES: Array<string> = [
  '1.2.840.10008.5.1.4.1.1.7', // Secondary Capture SOP Class UID
];

// this is the list that appears in renderers.ts
// the module that consumes it has a safe fallback, but new modalities should be added here if necessary.
export const SOP_CLASS_TO_MODALITY_MAP: { [string]: string } = {
  '1.2.840.10008.5.1.4.1.1.7': 'Secondary Capture',
  '1.2.840.10008.5.1.4.1.1.4': 'MR',
  '1.2.840.10008.5.1.4.1.1.1.2': 'Mammography',
  '1.2.840.10008.5.1.4.1.1.2': 'CT',
  '1.2.840.10008.1.1': 'Verification',
  '1.2.840.10008.1.3.10': 'Media Storage Directory',
  '1.2.840.10008.5.1.4.1.1.1': 'Computed Radiography',
  '1.2.840.10008.5.1.4.1.1.1.1': 'Digital X-Ray (Presentation)',
  '1.2.840.10008.5.1.4.1.1.1.1.1': 'Digital X-Ray (Processing)',
  '1.2.840.10008.5.1.4.1.1.1.2.1': 'Digital Mammography X-Ray (Processing)',
  '1.2.840.10008.5.1.4.1.1.1.3': 'Digital Intra-oral X-Ray (Presentation)',
  '1.2.840.10008.5.1.4.1.1.1.3.1': 'Digital Intra-oral X-Ray (Processing)',
  '1.2.840.10008.5.1.4.1.1.2.1': 'Enhanced CT',
  '1.2.840.10008.5.1.4.1.1.4.1': 'Enhanced MR',
  '1.2.840.10008.5.1.4.1.1.4.2': 'MR Spectroscopy',
  '1.2.840.10008.5.1.4.1.1.6.1': 'Ultrasound',
  'a.1d5ab1838f8391c3a7ee17c8ddb69b7c2c88b0b3268afe3ad01122a013a5b90f':
    'Internal ID from Mahajan data set',
  'a.5ec8df3429bbe5da5a9354eecb73659f563642d3b1d045fddf5648a2ac773744': 'Added on EN-4232',
  '1.2.840.10008.5.1.4.1.1.12.1': 'X-Ray Angiographic',
  '1.2.840.10008.5.1.4.1.1.12.1.1': 'Enhanced XA',
  '1.2.840.10008.5.1.4.1.1.12.2': 'X-Ray Radiofluoroscopic',
  '1.2.840.10008.5.1.4.1.1.12.2.1': 'Enhanced XRF',
  '1.2.840.10008.5.1.4.1.1.20': 'Nuclear Medicine',
  '1.2.840.10008.5.1.4.1.1.66': 'Raw Data',
  '1.2.840.10008.5.1.4.1.1.66.1': 'Spatial Registration',
  '1.2.840.10008.5.1.4.1.1.66.2': 'Spatial Fiducials',
  '1.2.840.10008.5.1.4.1.1.66.3': 'Deformable Spatial Registration',
  '1.2.840.10008.5.1.4.1.1.66.4': 'Segmentation',
  '1.2.840.10008.5.1.4.1.1.67': 'Real World Value Mapping',
  '1.2.840.10008.5.1.4.1.1.77.1.5.3': 'Stereometric Relationship',
  '1.2.840.10008.5.1.4.1.1.88.33': 'Comprehensive SR',
  '1.2.840.10008.5.1.4.1.1.88.40': 'Procedure Log',
  '1.2.840.10008.5.1.4.1.1.88.50': 'Mammography CAD SR',
  '1.2.840.10008.5.1.4.1.1.88.65': 'Chest CAD SR',
  '1.2.840.10008.5.1.4.1.1.104.1': 'Encapsulated PDF',
  '1.2.840.10008.5.1.4.1.1.128': 'Positron Emission Tomography',
  '1.2.840.10008.5.1.4.1.1.481.1': 'RT Image',
  '1.2.840.10008.5.1.4.1.1.481.2': 'RT Dose',
  '1.2.840.10008.5.1.4.1.1.481.3': 'RT Structure Set',
  '1.2.840.10008.5.1.4.1.1.481.4': 'RT Beams Treatment Record',
  '1.2.840.10008.5.1.4.1.1.481.5': 'RT Plan',
  '1.2.840.10008.5.1.4.1.1.481.6': 'RT Brachy Treatment Record',
  '1.2.840.10008.5.1.4.1.1.481.7': 'RT Treatment Summary Record',
  '1.2.840.10008.5.1.4.1.1.481.8': 'RT Ion Plan',
  '1.2.840.10008.5.1.4.1.1.481.9': 'RT Ion Beams Treatment Record',
};

export const TOOL_RESTRICTIONS = {
  shortcut_only: 0,
  viewer_toolbar_only: 1,
  reporter_toolbar_only: 2,
};

/** Controls the maximum number of priors that can be loaded. Previously a feature flag of the same
 * name. */
export const MAX_COMPARISON_STUDIES = 10;

export type ViewportColorMap = {
  displayName: string,
  /** Color stops for the CSS gradient when displaying a preview in the pop-up menu */
  colorStops: string[],
  /** Value range for each color stop, ordered from lowest to highest */
  valueRange: number[],
  /** Color map preset name recognized by vtk-js. Refer to https://github.com/Kitware/vtk-js/blob/master/Sources/Rendering/Core/ColorTransferFunction/ColorMaps.json */
  vtkColorMapPreset: ColorMapPreset,
};

const ALL_COLOR_MAPS: { [key: ColorMapPreset]: ViewportColorMap } = ColorMapJson;

/**
 * Need to add a custom color preset?
 * 1. Define an entry in dicomColorPalettes.json with a unique name as a key
 *    - Debug and test the normalization/conversion with the `creates a VTK preset from DICOM rgb string` unit test
 * 2. Write an entry below in `ViewportColorMaps`
 */

/** Pre-defined color maps available to apply to the viewport */
export const ViewportColorMaps: Array<ViewportColorMap> = [
  {
    ...ALL_COLOR_MAPS['Grayscale'],
    displayName: 'Grayscale',
  },
  {
    // $FlowFixMe[incompatible-type] Custom preset not in our typedefs
    ...ALL_COLOR_MAPS['DICOM Hot Iron'],
    displayName: 'Hot Iron',
  },
  {
    // $FlowFixMe[incompatible-type] Custom preset not in our typedefs
    ...ALL_COLOR_MAPS['DICOM PET'],
    displayName: 'PET',
  },
  {
    ...ALL_COLOR_MAPS['jet'],
    displayName: 'Jet',
  },
  {
    ...ALL_COLOR_MAPS['rainbow'],
    displayName: 'Rainbow',
  },

  // Toggle commenting the following lines to display ALL possible color map presets in the menu for
  // debugging

  // ...Object.values(ALL_COLOR_MAPS).filter(
  //   (colorMap) => !['Grayscale'].includes(colorMap.displayName)
  // ),
];
