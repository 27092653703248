// @flow

/**
 * Set of async utilities for IndexedDB, useful to avoid callback hell.
 */

export function idbCursor<A>(
  request: IDBRequest,
  iterator: (accumulator: A, cursor: IDBCursorWithValue, exit: () => void) => A,
  accumulator: A
): Promise<A> {
  return new Promise((resolve, reject) => {
    let shouldExit = false;
    function exit() {
      shouldExit = true;
    }

    request.addEventListener('success', (event: mixed) => {
      // $FlowIssue[incompatible-use] Flow support for IDB is not complete
      const cursor: IDBCursorWithValue = event.target.result;
      if (cursor == null || shouldExit) {
        resolve(accumulator);
        return;
      }

      accumulator = iterator(accumulator, cursor, exit);
      cursor.continue();
    });

    request.addEventListener('error', (event: mixed) => {
      // $FlowIssue[incompatible-use] Flow support for IDB is not complete
      reject(event.target.error);
    });
  });
}

export function idbGet<T>(objectStore: IDBObjectStore, itemKey: string): Promise<?T> {
  return new Promise((resolve, reject) => {
    const request = objectStore.get(itemKey);

    request.addEventListener('success', (event: mixed) => {
      // $FlowIssue[incompatible-use] Flow support for IDB is not complete
      resolve(event.target.result);
    });
    request.addEventListener('error', (event: mixed) => {
      // $FlowIssue[incompatible-use] Flow support for IDB is not complete
      reject(event.target.error);
    });
  });
}

export function idbTransaction(
  db: IDBDatabase,
  storeNames: string | string[],
  mode?: 'readonly' | 'readwrite' | 'versionchange',
  callback: (transaction: IDBTransaction) => Promise<mixed>
): Promise<void> {
  return new Promise(async (resolve, reject) => {
    const transaction = db.transaction(storeNames, mode);

    transaction.addEventListener('complete', () => {
      resolve();
    });

    transaction.addEventListener('error', (event: mixed) => {
      // $FlowIssue[incompatible-use] Flow support for IDB is not complete
      reject(event.target.error);
    });

    await callback(transaction);
  });
}
