// @flow

import type { WorkspacePreset, WorkspacePresetsFieldsFragment } from 'generated/graphql';
import type { BrowserWindow } from 'common/WorkspaceManager';

import { getSironaUrlFromScreenName } from 'utils/screenNames';
import { LISTENER_EVENTS } from './constants';

export const readCaseEvent = ({
  smid,
  target,
  workspacePreset,
}: {
  smid: string,
  target: string,
  workspacePreset?: WorkspacePreset,
}): CustomEvent => {
  return new CustomEvent(LISTENER_EVENTS.READ_CASE, {
    detail: {
      smid,
      target,
    },
  });
};

export const closeEvent = (): CustomEvent => {
  return new CustomEvent(LISTENER_EVENTS.CLOSE);
};

export const refreshEvent = (): CustomEvent => {
  return new CustomEvent(LISTENER_EVENTS.REFRESH);
};

export const initEvent = (): CustomEvent => {
  return new CustomEvent(LISTENER_EVENTS.INIT);
};

export const applyWorkspacePresetEvent = ({
  preset,
  caseId,
}: {
  preset:
    | $FlowFixMe
    | {
        instanceId: string,
        windows: Array<$Diff<BrowserWindow, { tabs: mixed }>>,
      }
    | ({ +__typename?: 'WorkspacePreset' } & WorkspacePresetsFieldsFragment),
  caseId: string,
}): CustomEvent => {
  return new CustomEvent(LISTENER_EVENTS.APPLY_WORKSPACE_PRESET, {
    detail: {
      ...preset,
      windows: preset.windows?.map((win) => ({
        ...win,
        url: getSironaUrlFromScreenName(win.screen, caseId),
      })),
    },
  });
};

export const openWindowEvent = ({
  url,
  windowConfig,
}: {
  url: string,
  windowConfig: {
    focused: ?boolean,
    top: ?number,
    left: ?number,
    width: ?number,
    height: ?number,
    state: string,
  },
}): CustomEvent => {
  return new CustomEvent(LISTENER_EVENTS.OPEN_WINDOW, {
    detail: {
      url,
      target: url,
      config: windowConfig,
    },
  });
};

export const focusEvent = (): CustomEvent => {
  return new CustomEvent(LISTENER_EVENTS.FOCUS);
};

export const syncEvent = ({
  from,
  to,
  studies,
}: {
  from: ?string,
  to: string,
  studies: ?(string[]),
}): CustomEvent => {
  return new CustomEvent(LISTENER_EVENTS.SYNC, {
    detail: {
      from,
      to,
      studies,
    },
  });
};

export const extensionListenerReadyEvent = (): CustomEvent => {
  return new CustomEvent(LISTENER_EVENTS.APP_READY);
};

export const setCaseIdEvent = ({
  smid,
  shouldReload,
}: {
  smid: string,
  shouldReload?: ?boolean,
}): CustomEvent => {
  return new CustomEvent(LISTENER_EVENTS.SET_CASE_ID, {
    detail: {
      caseId: smid,
    },
  });
};

export const requestMemoryInfoEvent = (): CustomEvent => {
  return new CustomEvent(LISTENER_EVENTS.REQ_MEMORY_INFO);
};

export const addExtensionListener = (eventName: string, handler: mixed): void => {
  window.document.addEventListener(eventName, handler);
};

export const removeExtensionListener = (eventName: string, handler: mixed): void => {
  window.document.removeEventListener(eventName, handler);
};

export const dispatchExtensionEvent = (customEvent: CustomEvent): void => {
  window.document.dispatchEvent(customEvent);
};
