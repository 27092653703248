// @flow

// events the extension listens to (dispatched from application)
export const LISTENER_EVENTS: {
  READ_CASE: 'srna.readCase',
  CLOSE: 'srna.close',
  REFRESH: 'srna.refresh',
  INIT: 'srna.init',
  APPLY_WORKSPACE_PRESET: 'srna.setup.apply',
  OPEN_WINDOW: 'srna.open.window',
  FOCUS: 'srna.focus',
  SYNC: 'srna.sync',
  APP_READY: 'srna.extension-listener-ready',
  SET_CASE_ID: 'srna.set.caseid',
  REQ_MEMORY_INFO: 'srna.memory.info',
} = Object.freeze({
  READ_CASE: 'srna.readCase',
  CLOSE: 'srna.close',
  REFRESH: 'srna.refresh',
  INIT: 'srna.init',
  APPLY_WORKSPACE_PRESET: 'srna.setup.apply',
  OPEN_WINDOW: 'srna.open.window',
  FOCUS: 'srna.focus',
  SYNC: 'srna.sync',
  APP_READY: 'srna.extension-listener-ready',
  SET_CASE_ID: 'srna.set.caseid',
  REQ_MEMORY_INFO: 'srna.memory.info',
});

// events the extension responds with (dispatched from extension)
export const RESPONSE_EVENTS: {
  MEMORY_RESPONSE: 'srna.memory.info.response',
  EXTENSION_INJECTED: 'srna.extension-injected',
  WINDOW_STATE_UPDATED: 'srna.window.state.updated',
  PRIORS: 'srna.window.state.priors',
  SPAM_CLICK_DETECTED: 'srna.spam.click.detected',
  ICON_CLICKED: 'srna.icon.clicked',
  SYSTEM_INFO_UPDATED: 'srna.system.info',
  SHORTCUT_TRIGGERED: 'srna.shortcut.triggered',
} = Object.freeze({
  MEMORY_RESPONSE: 'srna.memory.info.response',
  EXTENSION_INJECTED: 'srna.extension-injected',
  WINDOW_STATE_UPDATED: 'srna.window.state.updated',
  PRIORS: 'srna.window.state.priors',
  SPAM_CLICK_DETECTED: 'srna.spam.click.detected',
  ICON_CLICKED: 'srna.icon.clicked',
  SYSTEM_INFO_UPDATED: 'srna.system.info',
  SHORTCUT_TRIGGERED: 'srna.shortcut.triggered',
});
