// @flow

/**
 * This file exists as an alternative to `modules/Apollo/client`
 * which cannot be imported inside Workers. Main differences-
 * - APP_VERSION directly pulled from env instead of constants file
 * - Read Session context taken from broadcast value rather than local storage
 * - In-memory cache is cut down for simplicity as it isn't used
 * - No Logout Link because the worker only has read access to location
 */

import type { ApolloCache } from '@apollo/client';
import { GRAPHQL_URL, GRAPHQL_WS_URL } from 'config';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, split } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { Kind, OperationTypeNode } from 'graphql';
import { createClient } from 'graphql-ws';
import { broadcastChannelAnalytics } from 'modules/analytics/broadcastChannelAnalytics';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import omitDeep from 'omit-deep-lodash';
import { usePregeneratedHashes } from 'graphql-codegen-persisted-query-ids/lib/apollo';
// $FlowIgnore[untyped-import] generated by codegen so we know it's safe
import persistedQueriesData from 'generated/persisted-queries';
import { env } from 'config/env';

const { GIT_TAG = '' } = env;
const APP_VERSION: string = GIT_TAG.startsWith('v') ? GIT_TAG.replace('v', '') : GIT_TAG || '';

const httpLink = new HttpLink({
  uri: GRAPHQL_URL,
  credentials: 'include',
  includeExtensions: true,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: GRAPHQL_WS_URL,
  })
);

const transportLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === Kind.OPERATION_DEFINITION &&
      definition.operation === OperationTypeNode.SUBSCRIPTION
    );
  },
  wsLink,
  httpLink
);

// This makes sure to add the read session related headers to all requests
// we will then read them on the graphql server and add them to the Datadog context
const readSessionLink = new ApolloLink((operation, forward) => {
  // WORKER DIFFERENCE- uses the broadcastChannelAnalytics read session instead of local storage
  const readSession = broadcastChannelAnalytics.readSession;
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'x-read-session': readSession != null ? JSON.stringify(readSession) : null,
    },
  }));

  return forward(operation);
});

const cache: ApolloCache<$FlowFixMe> = new InMemoryCache({});

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  const keysToOmit = ['__typename']; // more keys like timestamps could be included here

  const def = getMainDefinition(operation.query);

  if (def && def.operation === 'mutation') {
    operation.variables = omitDeep(operation.variables, keysToOmit);
  }
  return forward ? forward(operation) : null;
});

const persistedQueryLink = createPersistedQueryLink({
  // eslint-disable-next-line react-hooks/rules-of-hooks
  generateHash: usePregeneratedHashes(persistedQueriesData),
});

const link = ApolloLink.from([
  persistedQueryLink,
  readSessionLink,
  cleanTypenameLink,
  transportLink,
]);
// Configure the ApolloClient with the default cache
const workerClient: ApolloClient<$FlowFixMe> = new ApolloClient({
  name: 'workspace',
  version: APP_VERSION,
  link,
  cache,
  // This speeds up the initial load by 30%
  assumeImmutableResults: true,
});

export { workerClient };
