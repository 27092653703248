// @flow
import type { LogsEvent } from '@datadog/browser-logs';

export function datadogGlobalLogFilter(log: LogsEvent): false | void {
  if (
    log.http?.url?.includes('segment') &&
    log.error?.stack?.includes('TypeError: Failed to fetch')
  ) {
    return false;
  }

  // Datadog breaks its type contract and sometimes returns a message that is not a string
  // modules/logger local logs
  if (log.message != null && typeof log.message === 'string' && log.message.startsWith('%c[')) {
    return false;
  }
}
