// @flow
import { PATH } from '../config/constants';

import { matchPath } from 'react-router-dom';

export const PAGE_TYPES: {
  HOME: 'home',
  VIEWER: 'viewer',
  REPORTER: 'reporter',
  WORKLIST: 'worklist',
  URT: 'urt',
  PRODUCTPORTAL: 'productportal',
  LOGIN: 'login',
  ADMIN: 'admin',
  UNKNOWN: 'unknown',
} = Object.freeze({
  HOME: 'home',
  VIEWER: 'viewer',
  REPORTER: 'reporter',
  WORKLIST: 'worklist',
  URT: 'urt',
  PRODUCTPORTAL: 'productportal',
  LOGIN: 'login',
  ADMIN: 'admin',
  UNKNOWN: 'unknown',
});
export type PageTypes = $Values<typeof PAGE_TYPES>;

/**
 * Returns the pathname of the URL
 * @param {*} str Accepts either an absolute URl a full URL (i.e. location.href) or just a path (i.e. location.pathname or from the PATH constant object)
 * @returns {string} Returns the pathname of the URL. str is returned if it is not a valid URL
 */
export function getPathName(str: string): string {
  try {
    // Assume str is a path if it starts with a forward slash
    return new URL(str.startsWith('/') ? `${window.location.origin}${str}` : str).pathname;
  } catch (err) {
    return str;
  }
}

export function getPageType(url: string): PageTypes {
  const pathname = getPathName(url);

  if (matchPath(PATH.HOME, pathname)) {
    return PAGE_TYPES.HOME;
  } else if (matchPath(PATH.VIEWER, pathname)) {
    return PAGE_TYPES.VIEWER;
  } else if (matchPath(PATH.REPORTER, pathname)) {
    return PAGE_TYPES.REPORTER;
  } else if (matchPath(PATH.PATIENT_JACKET, pathname)) {
    return PAGE_TYPES.WORKLIST;
  } else if (matchPath(PATH.WORKLIST, pathname)) {
    return PAGE_TYPES.WORKLIST;
  } else if (matchPath(PATH.URT, pathname)) {
    return PAGE_TYPES.URT;
  } else if (matchPath(PATH.PRODUCTPORTAL, pathname)) {
    return PAGE_TYPES.PRODUCTPORTAL;
  } else if (matchPath(PATH.LOGIN, pathname)) {
    return PAGE_TYPES.LOGIN;
  } else if (matchPath(PATH.ADMIN, pathname)) {
    return PAGE_TYPES.ADMIN;
  } else {
    return PAGE_TYPES.UNKNOWN;
  }
}
