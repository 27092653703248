// @flow

import { DEPLOY_ENV } from 'config';
import { isMetric } from './constants';
import type { RumEvent } from '@datadog/browser-rum';

export const datadogGlobalEventFilter: (event: RumEvent, context: mixed) => void | boolean = (
  event,
  context
) => {
  // We need to make sure that the action name is instrumented. This is either:
  //
  // * One of our custom metrics OR
  // * An all lower-case string separated by dots, underscores, or hyphens
  //
  // We want to avoid sending the text content of the DOM node (due to PII/ PHI).
  if (event.type === 'action' && event.action != null) {
    const { action } = event;
    const actionName = action.target?.name;
    if (action.type === 'custom') {
      // This is a custom action, suppress if it isn't a valid metric name
      if (!isMetric(actionName)) {
        return false;
      }
    } else if (action.type === 'click') {
      // This is a DataDog automatically collected action. Make sure it doesn't
      // conflict with an existing custom metric name or contain potential PII / PHI

      // Instrumented actions must be all lowercase letters,
      // numbers, dashes, or underscores only
      if (actionName != null && !actionName.match(/^[a-z]+[-|_[a-z0-9]*]*$/)) {
        if (DEPLOY_ENV === 'production') {
          // In production environments, we will completely suppress recording this
          // non-instrumented action so we don't inadvertently send any PII / PHI
          return false;
        } else {
          // If this is not the case and we're in a lower environment, change the name
          // to something recognizable so we can see it in the DataDog dashboard and
          // get it instrumented.
          const conflictsWithCustomMetric = isMetric(actionName);

          const updatedActionName = conflictsWithCustomMetric
            ? `__CONFLICTS_WITH_CUSTOM__ - ${actionName}`
            : `__NEEDS_INSTRUMENTED__ - ${actionName}`;

          if (action.target != null) {
            action.target.name = updatedActionName;
          }
          event.action = action;
        }
      }
    }
  }

  if (event.type === 'error') {
    // Reporter > RichTextEditor, make error message consistent so it is grouped by DataDog
    if (event.error.message.includes('Cannot find a descendant at path')) {
      event.error.message = 'console error: [RichTextEditor] Cannot find a descendant at path';
    }
  }

  // Ignore data urls as they are not useful and too large to be sent
  if (event.resource?.url?.startsWith('data:')) {
    // $FlowIssue[incompatible-use] refinement is not working?
    event.resource.url = 'data:...';
  }
};
