// @flow
import type { FullSingleLayerStack } from 'domains/viewer/ViewportsConfigurations/types';
import type { AnalyticEventName } from 'modules/analytics/analytics';
import { viewer } from 'modules/analytics/constants';
import { PixelDataLoader } from '../PixelDataLoader';
import type { LoaderCallbackArg, TransferType } from '../PixelDataLoader';
import type { Json } from 'generated/graphql';
import { stackToSlimStack } from 'domains/viewer/ViewportsConfigurations/manipulators';
import { logger } from '../../logger';
import type { SupportedTextureTypes, SupportedTexturesMap } from 'utils/textureUtils';

export const PixelDataSharedWorkerError = {
  OutOfMemory: 'Pixel Data OOM Transfer Error',
  NotEnoughMemory: 'Cannot fully load selected stacks. Reduce layout size and try again.',
  FrameNotFound: 'Frame not found',
  FailedToFetch: 'Failed to fetch pixel data from cache or DCMData',
};

type LRUCache = {
  cache: Map<MessagePort, MessagePort>,
  forEach((MessagePort) => void): void,
  get(MessagePort): ?MessagePort,
  set(MessagePort, MessagePort): MessagePort,
};

type LoadCompleteMessage = { type: 'load-complete', stackSmid: string };
type LoadErrorMessage = { type: 'load-error', stackSmid: string, error: string };
export type HandleFrameMessage = {
  type: 'handle-frame',
  frameSmid: string,
  stackSmid: string,
  pixels: SupportedTextureTypes,
  range: [number, number],
  fromCache: boolean,
};
export type HandleInitialFrameMessage = {
  ...HandleFrameMessage,
  type: 'handle-initial-frame',
  volume?: SupportedTextureTypes,
};
type AnalyticsErrorMessage = {
  type: 'analytics-error',
  error: Error,
  data?: Json,
};

type AnalyticsTrackMessage = {
  type: 'analytics-track',
  name: AnalyticEventName,
  data?: Json,
};

type TransferCancelledMessage = {
  type: 'transfer-cancelled',
};

type PongMessage = {
  type: 'pong',
  inits: number,
};

export type WorkerSingleMessageData =
  | PongMessage
  | LoadCompleteMessage
  | LoadErrorMessage
  | HandleFrameMessage
  | HandleInitialFrameMessage
  | AnalyticsErrorMessage
  | AnalyticsTrackMessage
  | TransferCancelledMessage;

type BatchedMessages = { type: 'batched-messages', messages: WorkerSingleMessageData[] };

export type WorkerMessageData = WorkerSingleMessageData | BatchedMessages;

type InitMessage = {
  type: 'init',
  transferType: TransferType,
  SUPPORTED_TEXTURES: SupportedTexturesMap,
};

type PingMessage = {
  type: 'ping',
};

type LoadStackMessage = {
  type: 'load-stack',
  stack: FullSingleLayerStack,
  initialSlice: number,
  partOfInitialLoad: boolean,
  isDropped: boolean,
  priority: number,
};

export type LoadFrameMessage = {
  type: 'load-frame',
  stack: FullSingleLayerStack,
  frameIndex: number,
  priority: number,
};

type UpdatePriorityMessage = {
  type: 'update-priority',
  stack: FullSingleLayerStack,
  focus: number,
  isDropped: boolean,
  priority: number,
};

type CancelTransferMessage = {
  type: 'cancel-transfer',
  stack?: FullSingleLayerStack,
};

export type InboundMessageData =
  | PingMessage
  | LoadStackMessage
  | LoadFrameMessage
  | InitMessage
  | UpdatePriorityMessage
  | CancelTransferMessage;

type DataRequest = {
  stack: FullSingleLayerStack,
  focus: number,
  partOfInitialLoad: boolean,
  isDropped: boolean,
  processing: boolean,
  priority: number,
  messageQueue: Array<WorkerMessageData>,
};

type HandleMessage = (event: MessageEvent) => void;

export const singleFrameRequest: {
  messageQueue: Array<WorkerMessageData>,
} = { messageQueue: [] };

const REMAINING_FRAMES_TIMEOUT = 250;
const MAX_PAYLOAD = 500 * 1024 * 1024; // 500 MB

/*
 * The PixelWorkerConnection is unique to each page connecting.
 * We want a global init count so that all pages can wait and see
 * the Viewer pages connecting. This lets the Worker page close
 * after the Shared Worker is stable and in use by others.
 */
let inits: number = 0;
export function __TEST__resetInits() {
  inits = 0;
}

export class PixelWorkerConnection {
  port: MessagePort;
  allPorts: LRUCache;
  activeRequests: Map<string, DataRequest>;
  analytics: {
    track: (name: AnalyticEventName, data?: Json) => void,
    error: (error: Error, data?: Json) => void,
  };
  initialSendComplete: boolean;
  #initialFrameMessages: Array<WorkerMessageData>;
  #initialRequestProcessingTimeout: TimeoutID | null;
  #hasInitialLoadBeenHandled: boolean = false;
  #remainingFramesTimeout: TimeoutID | null;
  #shouldQueueMessages: boolean;
  #dataLoader: PixelDataLoader;

  constructor(port: MessagePort, allPorts: LRUCache) {
    this.port = port;
    this.allPorts = allPorts;
    this.activeRequests = new Map<string, DataRequest>();
    this.initialSendComplete = false;
    this.#initialFrameMessages = [];
    this.#initialRequestProcessingTimeout = null;
    this.#remainingFramesTimeout = null;
    this.#shouldQueueMessages = true;
    this.port.onmessage = this.handleMessage.bind(this);
    this.analytics = {
      track(name: AnalyticEventName, data: ?Json) {
        port.postMessage({ type: 'analytics-track', name, data });
      },
      error(error: Error, data: ?Json) {
        port.postMessage({ type: 'analytics-error', error, data });
      },
    };
  }

  handleMessage: HandleMessage = (messageEvent) => {
    // $FlowIgnore[incompatible-cast] - we assume we'll always receive valid messages
    const data = (messageEvent.data: InboundMessageData);
    switch (data.type) {
      case 'init': {
        // Prevent recreating data loader when new providers initialize
        // It is treated as a single entity throughout the module
        if (this.#dataLoader == null) {
          this.#dataLoader = new PixelDataLoader({
            transferType: data.transferType,
            SUPPORTED_TEXTURES: data.SUPPORTED_TEXTURES,
          });
        } else {
          this.#dataLoader.refreshLoader();
        }
        inits++;
        // broadcast a connection/init step so the worker-initializer knows to close
        this.allPorts.forEach((port) => {
          port.postMessage({ type: 'pong', inits });
        });
        break;
      }
      case 'ping': {
        // respond the connection was successful so the worker-initializer will let the Viewers start
        this.port.postMessage({ type: 'pong', inits: inits });
        break;
      }
      case 'load-stack': {
        const { stack, initialSlice, partOfInitialLoad, isDropped, priority } = data;
        if (!this.activeRequests.get(stack.smid)) {
          const request = {
            stack,
            focus: initialSlice,
            partOfInitialLoad,
            processing: false,
            messageQueue: [],
            isDropped,
            priority,
          };

          this.analytics.track(viewer.sys.pixelWorkerLoadStack, {
            ...request,
            stack: stackToSlimStack(stack),
          });
          this.activeRequests.set(stack.smid, request);
          this.scheduleRequest(request);
        }
        break;
      }
      case 'load-frame': {
        const { stack, frameIndex, priority } = data;
        const callback = ({ data }: LoaderCallbackArg) => {
          if (data != null && typeof data === 'object') {
            const { frameSmid, pixels, range, fromCache } = data;
            this.queueMessage(singleFrameRequest, {
              type: 'handle-frame',
              stackSmid: stack.smid,
              frameSmid,
              range,
              pixels,
              fromCache,
            });
          }
        };
        this.#dataLoader.loadFrame({ stack, frameIndex, stackPriority: priority, callback });
        break;
      }
      case 'update-priority': {
        const { stack, focus, isDropped, priority } = data;
        const oldRequest = this.activeRequests.get(stack.smid);

        if (oldRequest != null) {
          oldRequest.focus = focus;
          oldRequest.isDropped = isDropped;
          oldRequest.priority = priority;
          this.#dataLoader.updatePriority({
            stack,
            focusFrameIndex: focus,
            stackPriority: priority,
          });
        }
        break;
      }
      case 'cancel-transfer': {
        const { stack } = data;

        if (stack == null) {
          logger.info('Cancelling all in-flight requests');
          this.#dataLoader?.cancel();
          this.port.postMessage({ type: 'transfer-cancelled' });
        } else {
          const oldRequest = this.activeRequests.get(stack.smid);

          if (oldRequest != null) {
            logger.info('Cancelling single request');
            this.#dataLoader?.cancel(stack.smid);
            this.activeRequests.delete(stack.smid);
            this.port.postMessage({ type: 'transfer-cancelled' });
          }
        }
        break;
      }
      default:
        throw new Error(`Unknown message received: ${data.type}`);
    }
  };

  scheduleRequest(request: DataRequest) {
    if (!request.partOfInitialLoad || this.#hasInitialLoadBeenHandled) {
      this.processRequest(request);
    } else {
      if (this.#initialRequestProcessingTimeout != null) {
        clearTimeout(this.#initialRequestProcessingTimeout);
      }

      this.#initialRequestProcessingTimeout = setTimeout(() => {
        this.#hasInitialLoadBeenHandled = true;
        const initialLoadRequests = this.getInitialLoadRequests();
        const initialStacksToLoad = [];

        initialLoadRequests.forEach((request) => {
          request.processing = true;

          initialStacksToLoad.push({
            stack: request.stack,
            initialFrameIndex: request.focus,
          });
        });

        this.#dataLoader.loadInitialStacks({
          initialStacks: initialStacksToLoad,
          stackPriority: request.priority,
          isDropped: request.isDropped,
          callback: (args) => {
            this.processDataLoaderCallback(args);
          },
        });
      }, 50);
    }
  }

  processRequest(request: DataRequest) {
    request.processing = true;

    this.#dataLoader.loadStack({
      stack: request.stack,
      initialFrameIndex: request.focus,
      stackPriority: request.priority,
      isDropped: request.isDropped,
      callback: (args) => {
        this.processDataLoaderCallback(args);
      },
    });
  }

  processDataLoaderCallback({ stackSmid, type, data }: LoaderCallbackArg): void {
    const request = this.activeRequests.get(stackSmid);

    if (request == null) {
      logger.debug(new Error('Pixel request not found'), {
        type,
        stackSmid,
      });
      return;
    }

    if (data == null && type !== 'complete') {
      this.analytics.error(new Error('No data provided to data loader callback'), {
        studySmid: request.stack.study.smid,
        seriesSmid: request.stack.series?.smid,
        stackSmid: request.stack.smid,
      });
      return;
    }

    switch (type) {
      case 'initial-frame':
        if (data != null && typeof data === 'object') {
          const { frameSmid, pixels, range, fromCache } = data;
          const initialFrameMessage: HandleInitialFrameMessage = {
            type: 'handle-initial-frame',
            stackSmid,
            frameSmid,
            range,
            pixels,
            fromCache,
          };
          const transferrables = [];

          if (request.stack.is3Dable === true) {
            // `new pixels.constructor` will call the same constructor as the original TypedArray
            // we want the volume to be the same type as the original pixels
            initialFrameMessage.volume = new pixels.constructor(
              request.stack.frames.length * pixels.length
            );
            transferrables.push(initialFrameMessage.volume.buffer);
          }

          this.port.postMessage(initialFrameMessage, transferrables);
        }
        break;
      case 'frame':
        if (data != null && typeof data === 'object') {
          const { frameSmid, pixels, range, fromCache } = data;
          this.queueMessage(request, {
            type: 'handle-frame',
            stackSmid,
            frameSmid,
            range,
            pixels,
            fromCache,
          });
        }
        break;
      case 'complete':
        this.queueMessage(request, {
          type: 'load-complete',
          stackSmid,
        });
        break;
      case 'error':
        if (typeof data === 'string') {
          this.queueMessage(request, {
            type: 'load-error',
            stackSmid,
            error: data,
          });
        }
        break;
      default:
        // Unsure why this is an error, flow complains about the `fromCache` property
        // from the `FrameData` type not being compatible `null` in the `Json` definition
        // for `{ [string]: Json }`
        this.analytics.error(new Error(`Unknown data loader callback type: ${type}`), { data });
    }
  }

  queueMessage(request: DataRequest | typeof singleFrameRequest, message: WorkerMessageData) {
    request.messageQueue.push(message);

    if (this.#shouldQueueMessages && this.#remainingFramesTimeout == null) {
      this.#remainingFramesTimeout = setTimeout(() => {
        this.#shouldQueueMessages = false;
        this.flushMessageQueue();
      }, REMAINING_FRAMES_TIMEOUT);
    } else if (!this.#shouldQueueMessages) {
      this.flushMessageQueue();
    }
  }

  getInitialLoadRequests(): DataRequest[] {
    const requestList = Array.from(this.activeRequests.values());
    return requestList.filter((r) => r.partOfInitialLoad);
  }

  sendMessages(messages: Array<WorkerMessageData>) {
    if (messages.length > 0) {
      this.port.postMessage({ type: 'batched-messages', messages });
      this.flushMessageQueue();
    }
  }

  flushMessageQueue() {
    const requestList = Array.from(this.activeRequests.values());
    const batchedMessages = [];
    let payloadSize = 0;
    let hasNextBatch = true;

    const processMessage = (request: DataRequest | typeof singleFrameRequest) => {
      const message = request.messageQueue.shift();

      if (message != null) {
        hasNextBatch = true;
        batchedMessages.push(message);

        if (message.type === 'load-complete') {
          this.analytics.track(viewer.sys.pixelWorkerLoadComplete, message);
        } else if (message.type === 'handle-frame' || message.type === 'handle-initial-frame') {
          payloadSize += message.pixels.byteLength;
        }
        if (message.type === 'load-complete' || message.type === 'load-error') {
          this.activeRequests.delete(message.stackSmid);
        }
      }
    };

    while (payloadSize < MAX_PAYLOAD && hasNextBatch) {
      hasNextBatch = false;
      processMessage(singleFrameRequest);
    }

    hasNextBatch = true;

    while (payloadSize < MAX_PAYLOAD && hasNextBatch) {
      hasNextBatch = false;
      requestList.forEach(processMessage);
    }

    if (batchedMessages.length > 0) {
      this.sendMessages(batchedMessages);
    }
  }
}
