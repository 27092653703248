// @flow

import type { BaseStack } from 'generated/graphql';
import type {
  DehydratedViewportConfiguration,
  DehydratedViewportsConfigurations,
  SlimSeries,
  SlimStudy,
  SlimFrame,
  StudyGroup,
  ViewportConfiguration,
  ViewportsConfigurations,
  SlimStack,
  Stack,
  Series,
  Study,
  Frame,
  FullMultiLayerStack,
} from './types';
import memoize from 'lodash.memoize';
import { decodeOverlayStackSmidToStackLayers, isStackSmidMultiLayer } from '../Viewer/stackUtils';

/**
 * Takes a list of viewport configurations and merges them into a single list.
 * The last occurrence is preserved, the list is NOT deep merged.
 */
export function mergeViewportsConfigurations(
  ...configurations: $ReadOnlyArray<?ViewportsConfigurations>
): ViewportsConfigurations {
  return configurations.reduce<ViewportsConfigurations>((acc, configuration) => {
    return {
      ...acc,
      ...configuration,
    };
  }, {});
}

export function viewportsConfigurationsEntries(
  viewportsConfigurations: ViewportsConfigurations | DehydratedViewportsConfigurations
): [string, ?ViewportConfiguration][] {
  // $FlowFixMe[prop-missing] (automated-migration-2023-01-22)
  return Object.entries(viewportsConfigurations);
}

export function filterViewportsConfigurations(
  viewportsConfigurations: ViewportsConfigurations | DehydratedViewportsConfigurations,
  filter: ([string, ?ViewportConfiguration]) => boolean
): ViewportsConfigurations {
  return Object.fromEntries(viewportsConfigurationsEntries(viewportsConfigurations).filter(filter));
}

export function dehydrateViewportConfiguration(
  viewportConfiguration: ViewportConfiguration
): DehydratedViewportConfiguration {
  return {
    ...viewportConfiguration,
    study: viewportConfiguration.study && { smid: viewportConfiguration.study.smid },
    series: viewportConfiguration.series && { smid: viewportConfiguration.series.smid },
    stack: viewportConfiguration.stack && { smid: viewportConfiguration.stack.smid },
  };
}
export function hydrateViewportConfiguration(
  viewportConfiguration: DehydratedViewportConfiguration,
  groupedStudies: $ReadOnlyArray<StudyGroup>
): ?ViewportConfiguration {
  const studies = groupedStudies.flat(1).reduce(
    (acc, study) => {
      const studySmid: string = study.smid;
      return { ...acc, [studySmid]: study };
    },
    ({}: { [string]: SlimStudy })
  );
  const series = groupedStudies
    .flat(1)
    .flatMap((study) => study.seriesList)
    .reduce((acc, series) => ({ ...acc, [series.smid]: series }), ({}: { [string]: SlimSeries }));
  const stacks = groupedStudies
    .flat(1)
    .flatMap((study) => study.stackedFrames)
    .reduce(
      (acc: { [string]: SlimStack }, stack) => {
        const stackSmid: string = stack.smid;
        return { ...acc, [stackSmid]: stack };
      },
      ({}: { [string]: SlimStack })
    );

  // We have a specific study we are looking for here, if it isnt mapped yet return null
  if (
    viewportConfiguration.wasDropped === true &&
    viewportConfiguration.study &&
    studies[viewportConfiguration.study.smid] == null
  ) {
    return null;
  }

  const config: ViewportConfiguration = {
    ...viewportConfiguration,
    study: viewportConfiguration.study && studies[viewportConfiguration.study.smid],
    series: viewportConfiguration.series && series[viewportConfiguration.series.smid],
    stack: null,
  };

  if (
    viewportConfiguration.stack &&
    viewportConfiguration.stack.smid != null &&
    isStackSmidMultiLayer(viewportConfiguration.stack.smid) &&
    viewportConfiguration.study != null &&
    viewportConfiguration.study.smid != null
  ) {
    config.stack = {
      smid: viewportConfiguration.stack.smid,
      type: '',
      study: {
        smid: viewportConfiguration.study.smid,
      },
    };
  } else {
    config.stack = viewportConfiguration.stack && stacks[viewportConfiguration.stack.smid];
  }

  return config;
}

export function dehydrateViewportsConfigurations(
  viewportsConfigurations: ViewportsConfigurations
): DehydratedViewportsConfigurations {
  return Object.fromEntries(
    viewportsConfigurationsEntries(viewportsConfigurations).map(([key, viewportConfiguration]) => [
      key,
      viewportConfiguration != null ? dehydrateViewportConfiguration(viewportConfiguration) : null,
    ])
  );
}

export function hydrateViewportsConfigurations(
  viewportsConfigurations: DehydratedViewportsConfigurations,
  groupedStudies: StudyGroup[]
): ViewportsConfigurations {
  const ret = Object.fromEntries(
    Object.entries(viewportsConfigurations).map(([key, viewportConfiguration]) => [
      key,
      viewportConfiguration != null
        ? hydrateViewportConfiguration(viewportConfiguration, groupedStudies)
        : null,
    ])
  );

  return ret;
}

export function studyToSlimStudy(study: Study): SlimStudy {
  return {
    smid: study.smid,
    description: study.description,
    seriesList: study.seriesList.map((series) => seriesToSlimSeries(series)),
    stackedFrames: study.stackedFrames.map((stack) => stackToSlimStack(stack)),
  };
}

export function seriesToSlimSeries(series: Series): SlimSeries {
  return {
    smid: series.smid,
    description: series.description,
    study: { smid: series.study.smid },
  };
}

export function stackToSlimStack(stack: Stack | BaseStack): SlimStack {
  const slim: SlimStack = {
    smid: stack.smid,
    type: stack.type,
    study: { smid: stack.study.smid },
  };

  if (stack.frames != null) {
    slim.frames =
      stack.frames.length > 0
        ? [
            {
              smid: stack.frames[0].smid,
              series: { smid: stack.frames[0].series.smid },
              sopClassUID: stack.frames[0].sopClassUID,
            },
          ]
        : [];
  }

  return slim;
}

export function frameToSlimFrame(frame: Frame): SlimFrame {
  return {
    smid: frame.smid,
    series: { smid: frame.series.smid },
    sopClassUID: '123',
  };
}

export const rehydrateSlimStackToLayeredStack: (
  slimStack: ?SlimStack,
  study: ?Study
) => ?FullMultiLayerStack = memoize(
  (slimStack: ?SlimStack, study: ?Study): ?FullMultiLayerStack => {
    if (study != null && slimStack != null && isStackSmidMultiLayer(slimStack.smid)) {
      const stack: FullMultiLayerStack = {
        __typename: 'LayeredStack',
        type: '',
        smid: slimStack.smid,
        stackLayers: decodeOverlayStackSmidToStackLayers(slimStack.smid),
        study: { smid: study.smid },
      };

      return stack;
    }
    return null;
  },
  (slimStack) => slimStack?.smid
);
