//@flow
// A list of the DICOM SOP Classes
// they are formatted as Keyword: UID
// Note: the following is not all valid DICOM UIDs!
//  these are JUST the ones whose type is "SOP Class"

export const Verification = '1.2.840.10008.1.1';
export const MediaStorageDirectoryStorage = '1.2.840.10008.1.3.10';
export const BasicStudyContentNotification = '1.2.840.10008.1.9';
export const StorageCommitmentPushModel = '1.2.840.10008.1.20.1';
export const StorageCommitmentPullModel = '1.2.840.10008.1.20.2';
export const ProceduralEventLogging = '1.2.840.10008.1.40';
export const SubstanceAdministrationLogging = '1.2.840.10008.1.42';
export const DetachedPatientManagement = '1.2.840.10008.3.1.2.1.1';
export const DetachedVisitManagement = '1.2.840.10008.3.1.2.2.1';
export const DetachedStudyManagement = '1.2.840.10008.3.1.2.3.1';
export const StudyComponentManagement = '1.2.840.10008.3.1.2.3.2';
export const ModalityPerformedProcedureStep = '1.2.840.10008.3.1.2.3.3';
export const ModalityPerformedProcedureStepRetrieve = '1.2.840.10008.3.1.2.3.4';
export const ModalityPerformedProcedureStepNotification = '1.2.840.10008.3.1.2.3.5';
export const DetachedResultsManagement = '1.2.840.10008.3.1.2.5.1';
export const DetachedInterpretationManagement = '1.2.840.10008.3.1.2.6.1';
export const BasicFilmSession = '1.2.840.10008.5.1.1.1';
export const BasicFilmBox = '1.2.840.10008.5.1.1.2';
export const BasicGrayscaleImageBox = '1.2.840.10008.5.1.1.4';
export const BasicColorImageBox = '1.2.840.10008.5.1.1.4.1';
export const ReferencedImageBox = '1.2.840.10008.5.1.1.4.2';
export const PrintJob = '1.2.840.10008.5.1.1.14';
export const BasicAnnotationBox = '1.2.840.10008.5.1.1.15';
export const Printer = '1.2.840.10008.5.1.1.16';
export const PrinterConfigurationRetrieval = '1.2.840.10008.5.1.1.16.376';
export const VOILUTBox = '1.2.840.10008.5.1.1.22';
export const PresentationLUT = '1.2.840.10008.5.1.1.23';
export const ImageOverlayBox = '1.2.840.10008.5.1.1.24';
export const BasicPrintImageOverlayBox = '1.2.840.10008.5.1.1.24.1';
export const PrintQueueManagement = '1.2.840.10008.5.1.1.26';
export const StoredPrintStorage = '1.2.840.10008.5.1.1.27';
export const HardcopyGrayscaleImageStorage = '1.2.840.10008.5.1.1.29';
export const HardcopyColorImageStorage = '1.2.840.10008.5.1.1.30';
export const PullPrintRequest = '1.2.840.10008.5.1.1.31';
export const MediaCreationManagement = '1.2.840.10008.5.1.1.33';
export const DisplaySystem = '1.2.840.10008.5.1.1.40';
export const ComputedRadiographyImageStorage = '1.2.840.10008.5.1.4.1.1.1';
export const DigitalXRayImageStorageForPresentation = '1.2.840.10008.5.1.4.1.1.1.1';
export const DigitalXRayImageStorageForProcessing = '1.2.840.10008.5.1.4.1.1.1.1.1';
export const DigitalMammographyXRayImageStorageForPresentation = '1.2.840.10008.5.1.4.1.1.1.2';
export const DigitalMammographyXRayImageStorageForProcessing = '1.2.840.10008.5.1.4.1.1.1.2.1';
export const DigitalIntraOralXRayImageStorageForPresentation = '1.2.840.10008.5.1.4.1.1.1.3';
export const DigitalIntraOralXRayImageStorageForProcessing = '1.2.840.10008.5.1.4.1.1.1.3.1';
export const CTImageStorage = '1.2.840.10008.5.1.4.1.1.2';
export const EnhancedCTImageStorage = '1.2.840.10008.5.1.4.1.1.2.1';
export const LegacyConvertedEnhancedCTImageStorage = '1.2.840.10008.5.1.4.1.1.2.2';
export const UltrasoundMultiFrameImageStorageRetired = '1.2.840.10008.5.1.4.1.1.3';
export const UltrasoundMultiFrameImageStorage = '1.2.840.10008.5.1.4.1.1.3.1';
export const MRImageStorage = '1.2.840.10008.5.1.4.1.1.4';
export const EnhancedMRImageStorage = '1.2.840.10008.5.1.4.1.1.4.1';
export const MRSpectroscopyStorage = '1.2.840.10008.5.1.4.1.1.4.2';
export const EnhancedMRColorImageStorage = '1.2.840.10008.5.1.4.1.1.4.3';
export const LegacyConvertedEnhancedMRImageStorage = '1.2.840.10008.5.1.4.1.1.4.4';
export const NuclearMedicineImageStorageRetired = '1.2.840.10008.5.1.4.1.1.5';
export const UltrasoundImageStorageRetired = '1.2.840.10008.5.1.4.1.1.6';
export const UltrasoundImageStorage = '1.2.840.10008.5.1.4.1.1.6.1';
export const EnhancedUSVolumeStorage = '1.2.840.10008.5.1.4.1.1.6.2';
export const SecondaryCaptureImageStorage = '1.2.840.10008.5.1.4.1.1.7';
export const MultiFrameSingleBitSecondaryCaptureImageStorage = '1.2.840.10008.5.1.4.1.1.7.1';
export const MultiFrameGrayscaleByteSecondaryCaptureImageStorage = '1.2.840.10008.5.1.4.1.1.7.2';
export const MultiFrameGrayscaleWordSecondaryCaptureImageStorage = '1.2.840.10008.5.1.4.1.1.7.3';
export const MultiFrameTrueColorSecondaryCaptureImageStorage = '1.2.840.10008.5.1.4.1.1.7.4';
export const StandaloneOverlayStorage = '1.2.840.10008.5.1.4.1.1.8';
export const StandaloneCurveStorage = '1.2.840.10008.5.1.4.1.1.9';
export const WaveformStorageTrial = '1.2.840.10008.5.1.4.1.1.9.1';
export const TwelveLeadECGWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.1.1';
export const GeneralECGWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.1.2';
export const AmbulatoryECGWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.1.3';
export const HemodynamicWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.2.1';
export const CardiacElectrophysiologyWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.3.1';
export const BasicVoiceAudioWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.4.1';
export const GeneralAudioWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.4.2';
export const ArterialPulseWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.5.1';
export const RespiratoryWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.6.1';
export const MultichannelRespiratoryWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.6.2';
export const RoutineScalpElectroencephalogramWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.7.1';
export const ElectromyogramWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.7.2';
export const ElectrooculogramWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.7.3';
export const SleepElectroencephalogramWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.7.4';
export const BodyPositionWaveformStorage = '1.2.840.10008.5.1.4.1.1.9.8.1';
export const StandaloneModalityLUTStorage = '1.2.840.10008.5.1.4.1.1.10';
export const StandaloneVOILUTStorage = '1.2.840.10008.5.1.4.1.1.11';
export const GrayscaleSoftcopyPresentationStateStorage = '1.2.840.10008.5.1.4.1.1.11.1';
export const ColorSoftcopyPresentationStateStorage = '1.2.840.10008.5.1.4.1.1.11.2';
export const PseudoColorSoftcopyPresentationStateStorage = '1.2.840.10008.5.1.4.1.1.11.3';
export const BlendingSoftcopyPresentationStateStorage = '1.2.840.10008.5.1.4.1.1.11.4';
export const XAXRFGrayscaleSoftcopyPresentationStateStorage = '1.2.840.10008.5.1.4.1.1.11.5';
export const GrayscalePlanarMPRVolumetricPresentationStateStorage = '1.2.840.10008.5.1.4.1.1.11.6';
export const CompositingPlanarMPRVolumetricPresentationStateStorage =
  '1.2.840.10008.5.1.4.1.1.11.7';
export const AdvancedBlendingPresentationStateStorage = '1.2.840.10008.5.1.4.1.1.11.8';
export const VolumeRenderingVolumetricPresentationStateStorage = '1.2.840.10008.5.1.4.1.1.11.9';
export const SegmentedVolumeRenderingVolumetricPresentationStateStorage =
  '1.2.840.10008.5.1.4.1.1.11.10';
export const MultipleVolumeRenderingVolumetricPresentationStateStorage =
  '1.2.840.10008.5.1.4.1.1.11.11';
export const XRayAngiographicImageStorage = '1.2.840.10008.5.1.4.1.1.12.1';
export const EnhancedXAImageStorage = '1.2.840.10008.5.1.4.1.1.12.1.1';
export const XRayRadiofluoroscopicImageStorage = '1.2.840.10008.5.1.4.1.1.12.2';
export const EnhancedXRFImageStorage = '1.2.840.10008.5.1.4.1.1.12.2.1';
export const XRayAngiographicBiPlaneImageStorage = '1.2.840.10008.5.1.4.1.1.12.3';
export const XRay3DAngiographicImageStorage = '1.2.840.10008.5.1.4.1.1.13.1.1';
export const XRay3DCraniofacialImageStorage = '1.2.840.10008.5.1.4.1.1.13.1.2';
export const BreastTomosynthesisImageStorage = '1.2.840.10008.5.1.4.1.1.13.1.3';
export const BreastProjectionXRayImageStorageForPresentation = '1.2.840.10008.5.1.4.1.1.13.1.4';
export const BreastProjectionXRayImageStorageForProcessing = '1.2.840.10008.5.1.4.1.1.13.1.5';
export const IntravascularOpticalCoherenceTomographyImageStorageForPresentation =
  '1.2.840.10008.5.1.4.1.1.14.1';
export const IntravascularOpticalCoherenceTomographyImageStorageForProcessing =
  '1.2.840.10008.5.1.4.1.1.14.2';
export const NuclearMedicineImageStorage = '1.2.840.10008.5.1.4.1.1.20';
export const ParametricMapStorage = '1.2.840.10008.5.1.4.1.1.30';
export const RawDataStorage = '1.2.840.10008.5.1.4.1.1.66';
export const SpatialRegistrationStorage = '1.2.840.10008.5.1.4.1.1.66.1';
export const SpatialFiducialsStorage = '1.2.840.10008.5.1.4.1.1.66.2';
export const DeformableSpatialRegistrationStorage = '1.2.840.10008.5.1.4.1.1.66.3';
export const SegmentationStorage = '1.2.840.10008.5.1.4.1.1.66.4';
export const SurfaceSegmentationStorage = '1.2.840.10008.5.1.4.1.1.66.5';
export const TractographyResultsStorage = '1.2.840.10008.5.1.4.1.1.66.6';
export const RealWorldValueMappingStorage = '1.2.840.10008.5.1.4.1.1.67';
export const SurfaceScanMeshStorage = '1.2.840.10008.5.1.4.1.1.68.1';
export const SurfaceScanPointCloudStorage = '1.2.840.10008.5.1.4.1.1.68.2';
export const VLImageStorageTrial = '1.2.840.10008.5.1.4.1.1.77.1';
export const VLMultiFrameImageStorageTrial = '1.2.840.10008.5.1.4.1.1.77.2';
export const VLEndoscopicImageStorage = '1.2.840.10008.5.1.4.1.1.77.1.1';
export const VideoEndoscopicImageStorage = '1.2.840.10008.5.1.4.1.1.77.1.1.1';
export const VLMicroscopicImageStorage = '1.2.840.10008.5.1.4.1.1.77.1.2';
export const VideoMicroscopicImageStorage = '1.2.840.10008.5.1.4.1.1.77.1.2.1';
export const VLSlideCoordinatesMicroscopicImageStorage = '1.2.840.10008.5.1.4.1.1.77.1.3';
export const VLPhotographicImageStorage = '1.2.840.10008.5.1.4.1.1.77.1.4';
export const VideoPhotographicImageStorage = '1.2.840.10008.5.1.4.1.1.77.1.4.1';
export const OphthalmicPhotography8BitImageStorage = '1.2.840.10008.5.1.4.1.1.77.1.5.1';
export const OphthalmicPhotography16BitImageStorage = '1.2.840.10008.5.1.4.1.1.77.1.5.2';
export const StereometricRelationshipStorage = '1.2.840.10008.5.1.4.1.1.77.1.5.3';
export const OphthalmicTomographyImageStorage = '1.2.840.10008.5.1.4.1.1.77.1.5.4';
export const WideFieldOphthalmicPhotographyStereographicProjectionImageStorage =
  '1.2.840.10008.5.1.4.1.1.77.1.5.5';
export const WideFieldOphthalmicPhotography3DCoordinatesImageStorage =
  '1.2.840.10008.5.1.4.1.1.77.1.5.6';
export const OphthalmicOpticalCoherenceTomographyEnFaceImageStorage =
  '1.2.840.10008.5.1.4.1.1.77.1.5.7';
export const OphthalmicOpticalCoherenceTomographyBscanVolumeAnalysisStorage =
  '1.2.840.10008.5.1.4.1.1.77.1.5.8';
export const VLWholeSlideMicroscopyImageStorage = '1.2.840.10008.5.1.4.1.1.77.1.6';
export const DermoscopicPhotographyImageStorage = '1.2.840.10008.5.1.4.1.1.77.1.7';
export const LensometryMeasurementsStorage = '1.2.840.10008.5.1.4.1.1.78.1';
export const AutorefractionMeasurementsStorage = '1.2.840.10008.5.1.4.1.1.78.2';
export const KeratometryMeasurementsStorage = '1.2.840.10008.5.1.4.1.1.78.3';
export const SubjectiveRefractionMeasurementsStorage = '1.2.840.10008.5.1.4.1.1.78.4';
export const VisualAcuityMeasurementsStorage = '1.2.840.10008.5.1.4.1.1.78.5';
export const SpectaclePrescriptionReportStorage = '1.2.840.10008.5.1.4.1.1.78.6';
export const OphthalmicAxialMeasurementsStorage = '1.2.840.10008.5.1.4.1.1.78.7';
export const IntraocularLensCalculationsStorage = '1.2.840.10008.5.1.4.1.1.78.8';
export const MacularGridThicknessAndVolumeReportStorage = '1.2.840.10008.5.1.4.1.1.79.1';
export const OphthalmicVisualFieldStaticPerimetryMeasurementsStorage =
  '1.2.840.10008.5.1.4.1.1.80.1';
export const OphthalmicThicknessMapStorage = '1.2.840.10008.5.1.4.1.1.81.1';
export const CornealTopographyMapStorage = '1.2.840.10008.5.1.4.1.1.82.1';
export const TextSRStorageTrial = '1.2.840.10008.5.1.4.1.1.88.1';
export const AudioSRStorageTrial = '1.2.840.10008.5.1.4.1.1.88.2';
export const DetailSRStorageTrial = '1.2.840.10008.5.1.4.1.1.88.3';
export const ComprehensiveSRStorageTrial = '1.2.840.10008.5.1.4.1.1.88.4';
export const BasicTextSRStorage = '1.2.840.10008.5.1.4.1.1.88.11';
export const EnhancedSRStorage = '1.2.840.10008.5.1.4.1.1.88.22';
export const ComprehensiveSRStorage = '1.2.840.10008.5.1.4.1.1.88.33';
export const Comprehensive3DSRStorage = '1.2.840.10008.5.1.4.1.1.88.34';
export const ExtensibleSRStorage = '1.2.840.10008.5.1.4.1.1.88.35';
export const ProcedureLogStorage = '1.2.840.10008.5.1.4.1.1.88.40';
export const MammographyCADSRStorage = '1.2.840.10008.5.1.4.1.1.88.50';
export const KeyObjectSelectionDocumentStorage = '1.2.840.10008.5.1.4.1.1.88.59';
export const ChestCADSRStorage = '1.2.840.10008.5.1.4.1.1.88.65';
export const XRayRadiationDoseSRStorage = '1.2.840.10008.5.1.4.1.1.88.67';
export const RadiopharmaceuticalRadiationDoseSRStorage = '1.2.840.10008.5.1.4.1.1.88.68';
export const ColonCADSRStorage = '1.2.840.10008.5.1.4.1.1.88.69';
export const ImplantationPlanSRStorage = '1.2.840.10008.5.1.4.1.1.88.70';
export const AcquisitionContextSRStorage = '1.2.840.10008.5.1.4.1.1.88.71';
export const SimplifiedAdultEchoSRStorage = '1.2.840.10008.5.1.4.1.1.88.72';
export const PatientRadiationDoseSRStorage = '1.2.840.10008.5.1.4.1.1.88.73';
export const PlannedImagingAgentAdministrationSRStorage = '1.2.840.10008.5.1.4.1.1.88.74';
export const PerformedImagingAgentAdministrationSRStorage = '1.2.840.10008.5.1.4.1.1.88.75';
export const EnhancedXRayRadiationDoseSRStorage = '1.2.840.10008.5.1.4.1.1.88.76';
export const ContentAssessmentResultsStorage = '1.2.840.10008.5.1.4.1.1.90.1';
export const MicroscopyBulkSimpleAnnotationsStorage = '1.2.840.10008.5.1.4.1.1.91.1';
export const EncapsulatedPDFStorage = '1.2.840.10008.5.1.4.1.1.104.1';
export const EncapsulatedCDAStorage = '1.2.840.10008.5.1.4.1.1.104.2';
export const EncapsulatedSTLStorage = '1.2.840.10008.5.1.4.1.1.104.3';
export const EncapsulatedOBJStorage = '1.2.840.10008.5.1.4.1.1.104.4';
export const EncapsulatedMTLStorage = '1.2.840.10008.5.1.4.1.1.104.5';
export const PositronEmissionTomographyImageStorage = '1.2.840.10008.5.1.4.1.1.128';
export const LegacyConvertedEnhancedPETImageStorage = '1.2.840.10008.5.1.4.1.1.128.1';
export const StandalonePETCurveStorage = '1.2.840.10008.5.1.4.1.1.129';
export const EnhancedPETImageStorage = '1.2.840.10008.5.1.4.1.1.130';
export const BasicStructuredDisplayStorage = '1.2.840.10008.5.1.4.1.1.131';
export const CTDefinedProcedureProtocolStorage = '1.2.840.10008.5.1.4.1.1.200.1';
export const CTPerformedProcedureProtocolStorage = '1.2.840.10008.5.1.4.1.1.200.2';
export const ProtocolApprovalStorage = '1.2.840.10008.5.1.4.1.1.200.3';
export const ProtocolApprovalInformationModelFind = '1.2.840.10008.5.1.4.1.1.200.4';
export const ProtocolApprovalInformationModelMove = '1.2.840.10008.5.1.4.1.1.200.5';
export const ProtocolApprovalInformationModelGet = '1.2.840.10008.5.1.4.1.1.200.6';
export const XADefinedProcedureProtocolStorage = '1.2.840.10008.5.1.4.1.1.200.7';
export const XAPerformedProcedureProtocolStorage = '1.2.840.10008.5.1.4.1.1.200.8';
export const InventoryStorage = '1.2.840.10008.5.1.4.1.1.201.1';
export const InventoryFind = '1.2.840.10008.5.1.4.1.1.201.2';
export const InventoryMove = '1.2.840.10008.5.1.4.1.1.201.3';
export const InventoryGet = '1.2.840.10008.5.1.4.1.1.201.4';
export const InventoryCreation = '1.2.840.10008.5.1.4.1.1.201.5';
export const RepositoryQuery = '1.2.840.10008.5.1.4.1.1.201.6';
export const RTImageStorage = '1.2.840.10008.5.1.4.1.1.481.1';
export const RTDoseStorage = '1.2.840.10008.5.1.4.1.1.481.2';
export const RTStructureSetStorage = '1.2.840.10008.5.1.4.1.1.481.3';
export const RTBeamsTreatmentRecordStorage = '1.2.840.10008.5.1.4.1.1.481.4';
export const RTPlanStorage = '1.2.840.10008.5.1.4.1.1.481.5';
export const RTBrachyTreatmentRecordStorage = '1.2.840.10008.5.1.4.1.1.481.6';
export const RTTreatmentSummaryRecordStorage = '1.2.840.10008.5.1.4.1.1.481.7';
export const RTIonPlanStorage = '1.2.840.10008.5.1.4.1.1.481.8';
export const RTIonBeamsTreatmentRecordStorage = '1.2.840.10008.5.1.4.1.1.481.9';
export const RTPhysicianIntentStorage = '1.2.840.10008.5.1.4.1.1.481.10';
export const RTSegmentAnnotationStorage = '1.2.840.10008.5.1.4.1.1.481.11';
export const RTRadiationSetStorage = '1.2.840.10008.5.1.4.1.1.481.12';
export const CArmPhotonElectronRadiationStorage = '1.2.840.10008.5.1.4.1.1.481.13';
export const TomotherapeuticRadiationStorage = '1.2.840.10008.5.1.4.1.1.481.14';
export const RoboticArmRadiationStorage = '1.2.840.10008.5.1.4.1.1.481.15';
export const RTRadiationRecordSetStorage = '1.2.840.10008.5.1.4.1.1.481.16';
export const RTRadiationSalvageRecordStorage = '1.2.840.10008.5.1.4.1.1.481.17';
export const TomotherapeuticRadiationRecordStorage = '1.2.840.10008.5.1.4.1.1.481.18';
export const CArmPhotonElectronRadiationRecordStorage = '1.2.840.10008.5.1.4.1.1.481.19';
export const RoboticRadiationRecordStorage = '1.2.840.10008.5.1.4.1.1.481.20';
export const RTRadiationSetDeliveryInstructionStorage = '1.2.840.10008.5.1.4.1.1.481.21';
export const RTTreatmentPreparationStorage = '1.2.840.10008.5.1.4.1.1.481.22';
export const EnhancedRTImageStorage = '1.2.840.10008.5.1.4.1.1.481.23';
export const EnhancedContinuousRTImageStorage = '1.2.840.10008.5.1.4.1.1.481.24';
export const RTPatientPositionAcquisitionInstructionStorage = '1.2.840.10008.5.1.4.1.1.481.25';
export const DICOSCTImageStorage = '1.2.840.10008.5.1.4.1.1.501.1';
export const DICOSDigitalXRayImageStorageForPresentation = '1.2.840.10008.5.1.4.1.1.501.2.1';
export const DICOSDigitalXRayImageStorageForProcessing = '1.2.840.10008.5.1.4.1.1.501.2.2';
export const DICOSThreatDetectionReportStorage = '1.2.840.10008.5.1.4.1.1.501.3';
export const DICOS2DAITStorage = '1.2.840.10008.5.1.4.1.1.501.4';
export const DICOS3DAITStorage = '1.2.840.10008.5.1.4.1.1.501.5';
export const DICOSQuadrupoleResonanceStorage = '1.2.840.10008.5.1.4.1.1.501.6';
export const EddyCurrentImageStorage = '1.2.840.10008.5.1.4.1.1.601.1';
export const EddyCurrentMultiFrameImageStorage = '1.2.840.10008.5.1.4.1.1.601.2';
export const PatientRootQueryRetrieveInformationModelFind = '1.2.840.10008.5.1.4.1.2.1.1';
export const PatientRootQueryRetrieveInformationModelMove = '1.2.840.10008.5.1.4.1.2.1.2';
export const PatientRootQueryRetrieveInformationModelGet = '1.2.840.10008.5.1.4.1.2.1.3';
export const StudyRootQueryRetrieveInformationModelFind = '1.2.840.10008.5.1.4.1.2.2.1';
export const StudyRootQueryRetrieveInformationModelMove = '1.2.840.10008.5.1.4.1.2.2.2';
export const StudyRootQueryRetrieveInformationModelGet = '1.2.840.10008.5.1.4.1.2.2.3';
export const PatientStudyOnlyQueryRetrieveInformationModelFind = '1.2.840.10008.5.1.4.1.2.3.1';
export const PatientStudyOnlyQueryRetrieveInformationModelMove = '1.2.840.10008.5.1.4.1.2.3.2';
export const PatientStudyOnlyQueryRetrieveInformationModelGet = '1.2.840.10008.5.1.4.1.2.3.3';
export const CompositeInstanceRootRetrieveMove = '1.2.840.10008.5.1.4.1.2.4.2';
export const CompositeInstanceRootRetrieveGet = '1.2.840.10008.5.1.4.1.2.4.3';
export const CompositeInstanceRetrieveWithoutBulkDataGet = '1.2.840.10008.5.1.4.1.2.5.3';
export const DefinedProcedureProtocolInformationModelFind = '1.2.840.10008.5.1.4.20.1';
export const DefinedProcedureProtocolInformationModelMove = '1.2.840.10008.5.1.4.20.2';
export const DefinedProcedureProtocolInformationModelGet = '1.2.840.10008.5.1.4.20.3';
export const ModalityWorklistInformationModelFind = '1.2.840.10008.5.1.4.31';
export const GeneralPurposeWorklistInformationModelFind = '1.2.840.10008.5.1.4.32.1';
export const GeneralPurposeScheduledProcedureStep = '1.2.840.10008.5.1.4.32.2';
export const GeneralPurposePerformedProcedureStep = '1.2.840.10008.5.1.4.32.3';
export const InstanceAvailabilityNotification = '1.2.840.10008.5.1.4.33';
export const RTBeamsDeliveryInstructionStorageTrial = '1.2.840.10008.5.1.4.34.1';
export const RTConventionalMachineVerificationTrial = '1.2.840.10008.5.1.4.34.2';
export const RTIonMachineVerificationTrial = '1.2.840.10008.5.1.4.34.3';
export const UnifiedProcedureStepPushTrial = '1.2.840.10008.5.1.4.34.4.1';
export const UnifiedProcedureStepWatchTrial = '1.2.840.10008.5.1.4.34.4.2';
export const UnifiedProcedureStepPullTrial = '1.2.840.10008.5.1.4.34.4.3';
export const UnifiedProcedureStepEventTrial = '1.2.840.10008.5.1.4.34.4.4';
export const UnifiedProcedureStepPush = '1.2.840.10008.5.1.4.34.6.1';
export const UnifiedProcedureStepWatch = '1.2.840.10008.5.1.4.34.6.2';
export const UnifiedProcedureStepPull = '1.2.840.10008.5.1.4.34.6.3';
export const UnifiedProcedureStepEvent = '1.2.840.10008.5.1.4.34.6.4';
export const UnifiedProcedureStepQuery = '1.2.840.10008.5.1.4.34.6.5';
export const RTBeamsDeliveryInstructionStorage = '1.2.840.10008.5.1.4.34.7';
export const RTConventionalMachineVerification = '1.2.840.10008.5.1.4.34.8';
export const RTIonMachineVerification = '1.2.840.10008.5.1.4.34.9';
export const RTBrachyApplicationSetupDeliveryInstructionStorage = '1.2.840.10008.5.1.4.34.10';
export const GeneralRelevantPatientInformationQuery = '1.2.840.10008.5.1.4.37.1';
export const BreastImagingRelevantPatientInformationQuery = '1.2.840.10008.5.1.4.37.2';
export const CardiacRelevantPatientInformationQuery = '1.2.840.10008.5.1.4.37.3';
export const HangingProtocolStorage = '1.2.840.10008.5.1.4.38.1';
export const HangingProtocolInformationModelFind = '1.2.840.10008.5.1.4.38.2';
export const HangingProtocolInformationModelMove = '1.2.840.10008.5.1.4.38.3';
export const HangingProtocolInformationModelGet = '1.2.840.10008.5.1.4.38.4';
export const ColorPaletteStorage = '1.2.840.10008.5.1.4.39.1';
export const ColorPaletteQueryRetrieveInformationModelFind = '1.2.840.10008.5.1.4.39.2';
export const ColorPaletteQueryRetrieveInformationModelMove = '1.2.840.10008.5.1.4.39.3';
export const ColorPaletteQueryRetrieveInformationModelGet = '1.2.840.10008.5.1.4.39.4';
export const ProductCharacteristicsQuery = '1.2.840.10008.5.1.4.41';
export const SubstanceApprovalQuery = '1.2.840.10008.5.1.4.42';
export const GenericImplantTemplateStorage = '1.2.840.10008.5.1.4.43.1';
export const GenericImplantTemplateInformationModelFind = '1.2.840.10008.5.1.4.43.2';
export const GenericImplantTemplateInformationModelMove = '1.2.840.10008.5.1.4.43.3';
export const GenericImplantTemplateInformationModelGet = '1.2.840.10008.5.1.4.43.4';
export const ImplantAssemblyTemplateStorage = '1.2.840.10008.5.1.4.44.1';
export const ImplantAssemblyTemplateInformationModelFind = '1.2.840.10008.5.1.4.44.2';
export const ImplantAssemblyTemplateInformationModelMove = '1.2.840.10008.5.1.4.44.3';
export const ImplantAssemblyTemplateInformationModelGet = '1.2.840.10008.5.1.4.44.4';
export const ImplantTemplateGroupStorage = '1.2.840.10008.5.1.4.45.1';
export const ImplantTemplateGroupInformationModelFind = '1.2.840.10008.5.1.4.45.2';
export const ImplantTemplateGroupInformationModelMove = '1.2.840.10008.5.1.4.45.3';
export const ImplantTemplateGroupInformationModelGet = '1.2.840.10008.5.1.4.45.4';
export const VideoEndoscopicImageRealTimeCommunication = '1.2.840.10008.10.1';
export const VideoPhotographicImageRealTimeCommunication = '1.2.840.10008.10.2';
export const AudioWaveformRealTimeCommunication = '1.2.840.10008.10.3';
export const RenditionSelectionDocumentRealTimeCommunication = '1.2.840.10008.10.4';
