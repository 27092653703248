// @flow

import type { FrameDataFragment, ImageParams } from 'generated/graphql';
import type { FullSingleLayerStack } from '../../../ViewportsConfigurations/types';
import type { $AxisDirection } from '@kitware/vtk.js';

import { calculateAxis } from '../../utils/math';
import { BaseImagingProvider } from './BaseImagingProvider';
import type { BaseImagingProviderArgs } from './BaseImagingProvider';
import { DRE_ALL_2D_VIEWPORT_TYPES, REVIEWED_THRESHOLD } from 'config/constants';
import type { DreAllViewportTypes } from 'config/constants';

export class BaseSingleStackImagingProvider<
  T: FullSingleLayerStack = FullSingleLayerStack,
> extends BaseImagingProvider<T> {
  #frameAxes: Set<$AxisDirection>;
  #validDirection: boolean;
  #frameSmidsMap: { [key: number]: string };
  #viewedFrameIndexes: Map<string, Map<DreAllViewportTypes, Set<number>>>;

  constructor(providerArgs: BaseImagingProviderArgs<T>) {
    super(providerArgs);

    // Calculate these once for the getters to reference
    this.#frameAxes = new Set(
      this.stack.frames.map((frame) => {
        return calculateAxis(frame.direction.data);
      })
    );

    this.#validDirection = this.stack.frames.every((frame) => frame.direction.validDirection);
    this.#frameSmidsMap = Object.fromEntries(
      this.stack.frames.map((frame, index) => [index, frame.smid])
    );

    const seriesSmids = new Set(this.stack.frames.map((frame) => frame.series.smid));
    this.#viewedFrameIndexes = new Map();
    seriesSmids.forEach((viewType) => {
      const seriesForView = new Map();
      DRE_ALL_2D_VIEWPORT_TYPES.forEach((seriesSmid) => seriesForView.set(seriesSmid, new Set()));
      this.#viewedFrameIndexes.set(viewType, seriesForView);
    });
  }

  hasValidDirection(): boolean {
    return this.#validDirection;
  }

  /* Map of the frame order in the series to the frame SMID */
  // $FlowIgnore[unsafe-getters-setters]
  get frameSmidsMap(): { [key: number]: string } {
    return this.#frameSmidsMap;
  }
  /* Number of slices / frames in the series */
  // $FlowIgnore[unsafe-getters-setters]
  get stackSize(): number {
    return this.stack.frames.length;
  }

  /* DICOM instance tags for this stack */
  // $FlowIgnore[unsafe-getters-setters]
  get frameTags(): $ReadOnlyArray<FrameDataFragment> {
    return this.stack.frames;
  }

  /**
   * General parameters for the image used in calculating transforms, positioning, etc.
   */
  // $FlowIgnore[unsafe-getters-setters]
  get imageParams(): ImageParams {
    return this.stack.imageParams;
  }

  getFrameAxes(): Set<$AxisDirection> {
    return this.#frameAxes;
  }

  hasPixels(): boolean {
    return this.stack.frames[0].hasPixels;
  }

  canRender(renderEngine: string): boolean {
    return this.stack.supportedRenderers[renderEngine] ?? false;
  }

  is3Dable(): boolean {
    return this.stack.is3Dable;
  }

  estimateMemory(): number {
    const perChannelBytes = this.supportedTextures.norm16 ? 2 : 4;
    const totalByteSize = this.stack.frames.reduce(
      (total, frame) =>
        total + (frame.colorChannels ?? 1) * perChannelBytes * frame.size[0] * frame.size[1],
      0
    );

    return totalByteSize;
  }

  estimatePerFrameMemory(): number {
    const frameSize = this.stack.frames.length;
    return this.estimateMemory() / frameSize;
  }

  seriesAreHomogenous(): boolean {
    return (
      this instanceof BaseSingleStackImagingProvider &&
      this.stack.frames.every((frame) => frame.series.smid === this.stack.frames[0].series.smid)
    );
  }

  getNumberOfColorChannels(frameIndex: number): number {
    const frame = this.stack.frames[frameIndex];
    const firstFrame = this.stack.frames[0];
    return frame?.colorChannels ?? firstFrame?.colorChannels ?? 1;
  }

  markSliceViewed(viewType: DreAllViewportTypes, slice: number): Set<string> {
    const seriesSmid = this.getFrameTagsForViewIndex(viewType, slice)?.series.smid ?? '';
    const seriesViewTypeViewedSlicesSet = this.#viewedFrameIndexes.get(seriesSmid)?.get(viewType);
    if (seriesViewTypeViewedSlicesSet == null) return new Set();

    seriesViewTypeViewedSlicesSet.add(slice);

    const reviewedSeries = new Set<string>();
    if (this.is3Dable()) {
      const [minSlices, maxSlices] = this._getViewTypeBounds(viewType);
      const viewTypeSize = Math.abs(maxSlices - minSlices);
      if (seriesViewTypeViewedSlicesSet.size >= viewTypeSize * REVIEWED_THRESHOLD) {
        reviewedSeries.add(seriesSmid);
      }
    } else {
      const framesMatchingSeriesCount = this.frameTags.reduce(
        (smfc, frame) => (smfc + frame.series.smid === seriesSmid ? 1 : 0),
        0
      );

      if (seriesViewTypeViewedSlicesSet.size >= framesMatchingSeriesCount * REVIEWED_THRESHOLD) {
        reviewedSeries.add(seriesSmid);
      }
    }

    return reviewedSeries;
  }
}
