// @flow
import type { ScreenName } from '../generated/graphql';
import { ScreenNameValues } from '../generated/graphql';
import { getWindowId } from '../hooks/useOpenTabs';
import { PAGE_TYPES, getPageType } from './pageTypes';

export function getScreenName(urlOrPathname: string): ScreenName {
  const pageType = getPageType(urlOrPathname);

  switch (pageType) {
    case PAGE_TYPES.VIEWER:
      if (getWindowId(urlOrPathname) === '0') {
        return ScreenNameValues.Viewer0;
      } else {
        return ScreenNameValues.Viewer1;
      }
    case PAGE_TYPES.REPORTER:
      return ScreenNameValues.Reporter;
    case PAGE_TYPES.WORKLIST:
      return ScreenNameValues.Worklist;
    default:
      throw new Error(`Unknown screen for ${urlOrPathname}`);
  }
}

export function getSironaUrlFromScreenName(screen: ScreenName, currentCaseId: ?string): string {
  const { protocol, host } = window.location;
  const w = currentCaseId != null ? `${currentCaseId}` : '';
  switch (screen) {
    case 'WORKLIST':
      return `${protocol}//${host}/worklist`;
    case 'REPORTER':
      return `${protocol}//${host}/case/${w}/reporter`;
    case 'VIEWER0':
      return `${protocol}//${host}/case/${w}/viewer/0`;
    case 'VIEWER1':
      return `${protocol}//${host}/case/${w}/viewer/1`;
    default:
      return `${protocol}//${host}/`;
  }
}
