// @flow

export const LOADING_PRIORITIES = Object.freeze({
  ACTIVE: 0,
  LINKED: 5,
  HANGED: 20,
  UNHANGED: 1000,
  PREFETCHING: 20000,
});

export type LoadingPriorities = typeof LOADING_PRIORITIES;
