// @flow
import type { AnalyticEventName } from './analytics';
import type { Json } from 'generated/graphql';
import { BroadcastChannel } from 'broadcast-channel';
import type { PageTypes } from 'utils/pageTypes';

type Target = {
  type: PageTypes,
  windowId?: string,
};

type Message =
  | {
      type: 'addContext',
      target: Target,
      key: string,
      value: Json,
    }
  | {
      type: 'track',
      target: Target,
      name: AnalyticEventName,
      data?: Json,
    };

export const channel: BroadcastChannel<Message> = new BroadcastChannel('worker-analytics-channel');

export const workerAnalytics = {
  addContext: (target: Target, key: string, value: Json) => {
    channel.postMessage({ type: 'addContext', target, key, value });
  },
  track: (target: Target, name: AnalyticEventName, data?: Json) => {
    channel.postMessage({ type: 'track', target, name, data });
  },
};
