// @flow
import type { ReadSessionContext } from './analytics';

import { BroadcastChannel } from 'broadcast-channel';

type BroadcastChannelAnalyticsMessage = {
  type: 'refreshReadSession',
  data: ?ReadSessionContext,
};

const channel = new BroadcastChannel<BroadcastChannelAnalyticsMessage>('srna-analytics');

export class BroadcastChannelAnalytics {
  #channel: BroadcastChannel<BroadcastChannelAnalyticsMessage>;

  readSession: ?ReadSessionContext = null;

  constructor() {
    this.#channel = channel;

    this.#channel.addEventListener('message', (event) => {
      if (event.type === 'refreshReadSession') {
        this.readSession = event.data;
      }
    });
  }

  refreshReadSession(readSession: ?ReadSessionContext) {
    this.#channel.postMessage({
      type: 'refreshReadSession',
      data: readSession,
    });
  }
}

export const broadcastChannelAnalytics: BroadcastChannelAnalytics = new BroadcastChannelAnalytics();
