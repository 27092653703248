// @flow

import type { RumEvent, RumContext, Context } from '@datadog/browser-rum';

export function apolloResourceBeforeSend(event: RumEvent, context: RumContext): boolean | void {
  if (event.type === 'resource' && event.resource.url.endsWith('/graphql')) {
    const { requestInit } = context;
    if (requestInit != null) {
      const { body } = requestInit;
      if (body != null) {
        const bodyJson = JSON.parse(body);
        const { operationName } = bodyJson;

        event.context = {
          // $FlowFixMe[incompatible-cast] datadog types are not great
          ...(event.context: Context),
          apollo: {
            operationName,
          },
        };
      }
    }
  }
}
