// @flow
import type {
  FullMultiLayerStack,
  FullSingleLayerStack,
  Series,
  Stack,
} from '../../../ViewportsConfigurations/types';
import vtkImageData from '@kitware/vtk.js/Common/DataModel/ImageData';
import type { FrameDataFragment, ImageParams } from 'generated/graphql';
import type { $AxisDirection } from '@kitware/vtk.js';
import type { DreAllViewportTypes } from 'config/constants';
import type { BaseImagingProviderArgs, ProviderStatus, SlicePlane } from './BaseImagingProvider';
import { BaseImagingProvider, emitterEvents } from './BaseImagingProvider';
import { getLPSDirections } from './utils';
import type { SupportedTextureTypes } from 'utils/textureUtils';
import { BaseSingleStackImagingProvider } from './BaseSingleStackImagingProvider';
import { logger } from 'modules/logger';

export type MultiStackImagingProviderArgs<T> = {
  ...BaseImagingProviderArgs<T>,
};

export class MultiStackImagingProvider<
  T: FullMultiLayerStack = FullMultiLayerStack,
> extends BaseImagingProvider<T> {
  #layerProviders: BaseImagingProvider<FullSingleLayerStack>[];

  constructor(args: MultiStackImagingProviderArgs<T>) {
    super(args);

    this.#layerProviders = [];
  }

  getLayerProvider(layerNumber: number): BaseImagingProvider<FullSingleLayerStack> {
    return this.#layerProviders[layerNumber];
  }

  isSopClass(sopClass: string): boolean {
    return this.#layerProviders.every((provider) =>
      provider.stack.frames.every((frame) => frame.sopClassUID.startsWith(sopClass))
    );
  }

  hasValidDirection(): boolean {
    return this.#layerProviders[0].hasValidDirection();
  }

  setupStack() {}

  _loadStack(): Promise<void> {
    return Promise.resolve();
  }

  seriesAreHomogenous(): boolean {
    return false;
  }

  // $FlowIgnore[unsafe-getters-setters]
  get vtkImage(): typeof vtkImageData {
    return this.#layerProviders[0].vtkImage;
  }

  /* Map of the frame order in the series to the frame SMID */
  // $FlowIgnore[unsafe-getters-setters]
  get frameSmidsMap(): { [key: number]: string } {
    if (this.#layerProviders.length > 0) {
      return Object.fromEntries(
        (this.#layerProviders[0].stack.frames ?? []).map((frame, index) => [index, frame.smid])
      );
    }

    return {};
  }

  /* Number of slices / frames in the series */
  // $FlowIgnore[unsafe-getters-setters]
  get stackSize(): number {
    if (this.#layerProviders.length > 0) {
      return this.#layerProviders.reduce((acc, provider) => {
        return (acc += provider.stackSize);
      }, 0);
    }
    return 0;
  }

  /* DICOM instance tags for this stack */
  // $FlowIgnore[unsafe-getters-setters]
  get frameTags(): $ReadOnlyArray<FrameDataFragment> {
    if (this.#layerProviders.length > 0) {
      return this.#layerProviders[0].frameTags;
    }

    return [];
  }

  /**
   * General parameters for the image used in calculating transforms, positioning, etc.
   */
  // $FlowIgnore[unsafe-getters-setters]
  get imageParams(): ImageParams {
    if (this.#layerProviders.length === 0) {
      throw new Error('Referenced providers have not been added');
    }

    return this.#layerProviders[0].imageParams;
  }

  getFrameAxes(): Set<$AxisDirection> {
    return this.#layerProviders[0].getFrameAxes();
  }

  hasPixels(): boolean {
    return true;
  }

  canRender(renderEngine: string): boolean {
    return true;
  }

  is3Dable(): boolean {
    if (this.#layerProviders.length > 0) {
      return this.#layerProviders.every((provider) => provider.is3Dable());
    }

    return false;
  }

  /**
   * Get the slice plane for the currently active slice of the given {viewType}.
   */
  getActiveSlicePlane(viewType: DreAllViewportTypes, viewportId: string): ?SlicePlane {
    const activeSlice = this.getActiveSlice(viewType, viewportId);
    return this.#layerProviders[0].getSlicePlaneAtViewIndex(viewType, activeSlice);
  }

  /**
   * Returns an object containing two vectors (normal and position vectors) for
   * the frame at the given {position} if the {position} is valid.
   *
   * Returns `null` for an invalid {position}
   */
  getSlicePlaneAtViewIndex(
    viewType: DreAllViewportTypes,
    position: number,
    layerIndex?: number = 0
  ): ?SlicePlane {
    if (layerIndex > this.#layerProviders.length - 1 || layerIndex < 0) {
      throw new Error('Invalid layer index');
    }
    return this.#layerProviders[layerIndex].getSlicePlaneAtViewIndex(viewType, position);
  }

  getComputedStatus(): ProviderStatus {
    const statuses = this.#layerProviders.map((provider) => provider.status);

    if (statuses.includes('error')) {
      return 'error';
    } else if (statuses.some((s) => s === 'loading')) {
      return 'loading';
    } else if (statuses.every((status) => status === 'complete')) {
      return 'complete';
    }

    return 'init';
  }

  getReferencedSeries(): Series[] {
    return this.stack.stackLayers
      .map((layer) => {
        const seriesSmid = this.study.stackedFrames.find((s) => s.smid === layer.stackSmid)?.series
          ?.smid;
        return this.study.seriesList.find((s) => s.smid === seriesSmid);
      })
      .filter(Boolean);
  }

  /** Create references to the providers that were configured in the multi-stack's `stackLayers` */
  calculateLayerProviders(stackProviders: Map<string, BaseImagingProvider<Stack>>) {
    // Get the ordered stackSmids of the stack layers, with the first as base and the second as the
    // layer
    const referencedSmids = this.stack.stackLayers.map((s) => s.stackSmid);
    const invalidStackSmids = [];
    referencedSmids.forEach((stackSmid) => {
      const targetProvider = stackProviders.get(stackSmid);
      if (!targetProvider || !(targetProvider instanceof BaseSingleStackImagingProvider)) {
        invalidStackSmids.push(stackSmid);
        return;
      }

      const handleStatus = (status: ProviderStatus) => {
        this.setStatus(this.getComputedStatus());
      };

      targetProvider.emitter.on(emitterEvents.frameLoaded, (frame) => {
        this.emitter.emit(emitterEvents.frameLoaded, this.getFramesLoaded());

        this.viewRef.current?.requestRender();
      });
      targetProvider.emitter.on(emitterEvents.stackLoaded, () => {
        this.emitter.emit(emitterEvents.stackLoaded);
      });
      targetProvider.emitter.on(emitterEvents.statusUpdated, handleStatus);

      this.#layerProviders.push(targetProvider);
    });

    // Find the difference of the smids matched and the smids referenced in the stackLayer
    if (invalidStackSmids.length) {
      logger.error(
        `Stack smids from the stackLayer configuration could not be resolved a valid stack provider`,
        { invalidStackSmids }
      );
    }

    this.setStatus(this.getComputedStatus());

    // After we calculate the referenced providers, set up the LPS directions
    const { direction } = this.imageParams;
    const lpsdirections = getLPSDirections(direction);

    this.lpsDirections = lpsdirections;
  }

  /** Returns the original ImagingProvider rendering the stackSmid provided from the list of
   * referenced providers */
  getRefProviderByStackSmid(stackSmid: string): ?BaseImagingProvider<Stack> {
    return this.#layerProviders.find((provider) => provider.stack.smid === stackSmid);
  }

  getNumberOfColorChannels(frameIndex: number): number {
    return this.#layerProviders[0].getNumberOfColorChannels(frameIndex);
  }

  getVolume(): SupportedTextureTypes {
    return this.#layerProviders[0].getVolume();
  }

  getRange(): [number, number] | null {
    return this.#layerProviders[0].getRange();
  }

  getFramesLoaded(): number {
    return this.#layerProviders.reduce((acc, provider) => {
      return (acc += provider.getFramesLoaded());
    }, 0);
  }

  isFrameLoaded(frameSmid: string): boolean {
    return this.#layerProviders[0].isFrameLoaded(frameSmid);
  }

  isReadyToRender(): boolean {
    return this.#layerProviders.every((p) => p.isReadyToRender());
  }

  getImage(frameSmid: ?string): typeof vtkImageData | null {
    return this.#layerProviders[0].getImage(frameSmid);
  }

  getFramePixels(frameSmid: string): SupportedTextureTypes | null {
    return this.#layerProviders[0].getFramePixels(frameSmid);
  }

  estimateMemory(): number {
    return 0;
  }

  getFrameOfReference(): ?string {
    return this.#layerProviders[0].getFrameOfReference();
  }

  isLayeredStack(): boolean {
    return true;
  }

  getSeries(): ?Series {
    return this.#layerProviders[0].getSeries();
  }

  getLayeredVTKImage(): typeof vtkImageData {
    return this.#layerProviders[1].vtkImage;
  }

  // mark each layer's current slice viewed and return the set of series smids
  // associated with those frames that are sufficiently reviewed, across all layers
  markSliceViewed(viewType: DreAllViewportTypes, slice: number): Set<string> {
    const baseLayerReviewedSeries = this.#layerProviders[0].markSliceViewed(viewType, slice);

    const reviewedSeries = new Set([...baseLayerReviewedSeries]);

    this.#layerProviders.slice(1).forEach((layerProvider) => {
      const layerSlicePlane = layerProvider?.getSlicePlaneAtViewIndex(viewType, slice);
      const layerSlice = layerProvider?.getSliceForWorldPosition(
        layerSlicePlane?.slicePosition,
        viewType
      );
      if (layerSlice != null) {
        layerProvider.markSliceViewed(viewType, layerSlice).forEach((reviewedSeriesForLayer) => {
          reviewedSeries.add(reviewedSeriesForLayer);
        });
      }
    });

    return reviewedSeries;
  }
}
