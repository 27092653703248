// @flow
import { vec3 } from 'gl-matrix';
import type { Vec3, Mat3 } from 'gl-matrix';
import type { Frame, Stack } from '../../../ViewportsConfigurations/types';
import vtkImageData from '@kitware/vtk.js/Common/DataModel/ImageData';
import { EncapsulatedPDFStorage } from 'domains/viewer/Viewer/SOPClasses';
import type { ViewType } from 'generated/graphql';

//////////////////////////////////////////////////////////////////////////////////////////
//
// Taken from: https://github.com/KitwareMedical/VolView/blob/master/src/utils/lps.ts#L79
//
//////////////////////////////////////////////////////////////////////////////////////////

/**
 * Maps LPS direction to world-space direction (not index-space direction)
 */
export type LPSDirections = {
  // These should match columns of the current image orientation matrix.
  Left: Vec3,
  Right: Vec3,
  Posterior: Vec3,
  Anterior: Vec3,
  Superior: Vec3,
  Inferior: Vec3,

  // maps LPS axis to column in direction matrix
  Coronal: 0 | 1 | 2,
  Sagittal: 0 | 1 | 2,
  Axial: 0 | 1 | 2,
};

export const defaultLPSDirections: () => LPSDirections = () => ({
  Left: vec3.fromValues(1, 0, 0),
  Right: vec3.fromValues(-1, 0, 0),
  Posterior: vec3.fromValues(0, 1, 0),
  Anterior: vec3.fromValues(0, -1, 0),
  Superior: vec3.fromValues(0, 0, 1),
  Inferior: vec3.fromValues(0, 0, -1),

  Sagittal: 0,
  Coronal: 1,
  Axial: 2,
});

/**
 * Associates the column vectors of a 3x3 matrix with the LPS axes.
 *
 * For each of the LPS axes, this function returns the associated column index (0, 1, 2)
 * in the provided 3x3 column-major matrix.
 *
 * Approach:
 *   - find the max of the direction matrix, ignoring columns and rows marked as done
 *   - assign the column vector of that max value to the row axis
 *   - mark that row and column as done
 *   - continue until all rows and columns are done
 */
export function getLPSDirections(direction: Mat3): LPSDirections {
  // Track the rows and columns that have yet to be assigned.
  const availableCols = [0, 1, 2];
  const availableRows = [0, 1, 2];
  const lpsDirs: LPSDirections = defaultLPSDirections();
  for (let i = 0; i < 3; i++) {
    let bestValue = 0;
    let bestValueLoc = [0, 0]; // col, row
    let removeIndices = [0, 0]; // indices into availableCols/Rows for deletion

    availableCols.forEach((col, colIdx) => {
      availableRows.forEach((row, rowIdx) => {
        const value = direction[col * 3 + row];
        if (Math.abs(value) > Math.abs(bestValue)) {
          bestValue = value;
          bestValueLoc = [col, row];
          removeIndices = [colIdx, rowIdx];
        }
      });
    });

    // the row index corresponds to the index of the LPS axis
    const [col, axis] = bestValueLoc;
    const axisVector = direction.slice(col * 3, (col + 1) * 3);
    const vecSign = Math.sign(bestValue);
    const posVector = axisVector.map((c) => c * vecSign);
    const negVector = axisVector.map((c) => c * -vecSign);
    if (axis === 0) {
      // Sagittal
      lpsDirs.Sagittal = col;
      lpsDirs.Left = [posVector[0], posVector[1], posVector[2]];
      lpsDirs.Right = [negVector[0], negVector[1], negVector[2]];
    } else if (axis === 1) {
      // Coronal
      lpsDirs.Coronal = col;
      lpsDirs.Posterior = [posVector[0], posVector[1], posVector[2]];
      lpsDirs.Anterior = [negVector[0], negVector[1], negVector[2]];
    } else if (axis === 2) {
      // Axial
      lpsDirs.Axial = col;
      lpsDirs.Superior = [posVector[0], posVector[1], posVector[2]];
      lpsDirs.Inferior = [negVector[0], negVector[1], negVector[2]];
    }

    availableCols.splice(removeIndices[0], 1);
    availableRows.splice(removeIndices[1], 1);
  }

  return lpsDirs;
}

export const getDefaultViewtypeForStack = (stack: Stack): ViewType => {
  const isEncapsulatedPdf =
    stack?.frames != null && stack?.frames[0].sopClassUID === EncapsulatedPDFStorage;
  return isEncapsulatedPdf ? 'PDF' : 'TWO_D_DRE';
};

export function createVtkImageDataInstanceForFrame(frame: Frame): typeof vtkImageData {
  return vtkImageData.newInstance({
    spacing: frame?.spacing,
    origin: frame?.origin,
    direction: frame?.direction.data,
    extent: [0, frame?.size[0] - 1 ?? 1, 0, frame?.size[1] - 1 ?? 1, 0, 0],
  });
}
