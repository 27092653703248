// @flow

import type { vec3 } from '@kitware/vtk.js/Common/Core/Math';
import { vec3 as vector3, mat4 } from 'gl-matrix';
import type { FrameDataFragment } from '../generated/graphql';

export const TAG_CACHE_KEY = 'tags-cache-v2';

export function generateCoordinateTransforms({
  origin,
  spacing,
  direction,
}: {
  origin: vec3,
  spacing: vec3,
  direction: [number, number, number, number, number, number, number, number, number],
}): { worldToIndex: (vec3) => vec3, indexToWorld: (vec3) => vec3 } {
  const indexToWorldTransformMatrix = mat4.create();
  const worldToIndexTransformMatrix = mat4.create();

  mat4.fromTranslation(indexToWorldTransformMatrix, [origin[0], origin[1], origin[2]]);

  indexToWorldTransformMatrix[0] = direction[0];
  indexToWorldTransformMatrix[1] = direction[1];
  indexToWorldTransformMatrix[2] = direction[2];
  indexToWorldTransformMatrix[4] = direction[3];
  indexToWorldTransformMatrix[5] = direction[4];
  indexToWorldTransformMatrix[6] = direction[5];
  indexToWorldTransformMatrix[8] = direction[6];
  indexToWorldTransformMatrix[9] = direction[7];
  indexToWorldTransformMatrix[10] = direction[8];

  mat4.scale(indexToWorldTransformMatrix, indexToWorldTransformMatrix, [
    spacing[0],
    spacing[1],
    spacing[2],
  ]);
  mat4.invert(worldToIndexTransformMatrix, indexToWorldTransformMatrix);

  return {
    worldToIndex: (worldCoordinates) => {
      const indexCoordinates = vector3.create();
      vector3.transformMat4(
        // Output - index coordinates
        indexCoordinates,
        // Input - world coordinates
        worldCoordinates,
        // transformation matrix
        worldToIndexTransformMatrix
      );
      return [...indexCoordinates];
    },
    indexToWorld: (indexCoordinates) => {
      const worldCoordinates = vector3.create();
      vector3.transformMat4(
        // Output - world coordinates
        worldCoordinates,
        // Input - index coordinates
        indexCoordinates,
        // indexToWorld transformation matrix (mat4)
        indexToWorldTransformMatrix
      );
      return [...worldCoordinates];
    },
  };
}

export function worldToIndex(
  tags:
    | {
        origin: FrameDataFragment['origin'],
        direction: FrameDataFragment['direction'],
        spacing: FrameDataFragment['spacing'],
      }
    | FrameDataFragment
): (vec3) => vec3 {
  const { origin, direction, spacing } = tags;

  const indexToWorldTransformMatrix = mat4.create();
  const worldToIndexTransformMatrix = mat4.create();

  mat4.fromTranslation(indexToWorldTransformMatrix, [origin[0], origin[1], origin[2]]);

  indexToWorldTransformMatrix[0] = direction.data[0];
  indexToWorldTransformMatrix[1] = direction.data[1];
  indexToWorldTransformMatrix[2] = direction.data[2];
  indexToWorldTransformMatrix[4] = direction.data[3];
  indexToWorldTransformMatrix[5] = direction.data[4];
  indexToWorldTransformMatrix[6] = direction.data[5];
  indexToWorldTransformMatrix[8] = direction.data[6];
  indexToWorldTransformMatrix[9] = direction.data[7];
  indexToWorldTransformMatrix[10] = direction.data[8];

  mat4.scale(indexToWorldTransformMatrix, indexToWorldTransformMatrix, [
    spacing[0],
    spacing[1],
    spacing[2],
  ]);
  mat4.invert(worldToIndexTransformMatrix, indexToWorldTransformMatrix);

  return (worldCoordinates) => {
    const indexCoordinates = vector3.create();
    vector3.transformMat4(
      indexCoordinates,
      // world coordinates
      worldCoordinates,
      // worldToIndexTransformMatrix (mat4)
      worldToIndexTransformMatrix
    );
    return [...indexCoordinates];
  };
}

export function indexToWorld(
  tags:
    | {
        origin: FrameDataFragment['origin'],
        direction: FrameDataFragment['direction'],
        spacing: FrameDataFragment['spacing'],
      }
    | FrameDataFragment
): (vec3) => vec3 {
  const { origin, direction, spacing } = tags;

  const indexToWorldTransformMatrix = mat4.create();
  const worldToIndexTransformMatrix = mat4.create();

  mat4.fromTranslation(indexToWorldTransformMatrix, [origin[0], origin[1], origin[2]]);

  indexToWorldTransformMatrix[0] = direction.data[0];
  indexToWorldTransformMatrix[1] = direction.data[1];
  indexToWorldTransformMatrix[2] = direction.data[2];
  indexToWorldTransformMatrix[4] = direction.data[3];
  indexToWorldTransformMatrix[5] = direction.data[4];
  indexToWorldTransformMatrix[6] = direction.data[5];
  indexToWorldTransformMatrix[8] = direction.data[6];
  indexToWorldTransformMatrix[9] = direction.data[7];
  indexToWorldTransformMatrix[10] = direction.data[8];

  mat4.scale(indexToWorldTransformMatrix, indexToWorldTransformMatrix, [
    spacing[0],
    spacing[1],
    spacing[2],
  ]);
  mat4.invert(worldToIndexTransformMatrix, indexToWorldTransformMatrix);

  return (indexCoordinates) => {
    const worldCoordinates = vector3.create();
    vector3.transformMat4(
      worldCoordinates,
      // world coordinates
      indexCoordinates,
      // worldToIndexTransformMatrix (mat4)
      indexToWorldTransformMatrix
    );
    return [...worldCoordinates];
  };
}

export const findSmallestTagSmid = (tags: FrameDataFragment[]): string => {
  let smallestIndex = 0;
  for (let i = 1; i < tags.length; i++) {
    if (tags[i].imageNumber < tags[smallestIndex].imageNumber) {
      smallestIndex = i;
    }
  }
  return tags[smallestIndex].smid;
};
