// @flow

import { DefaultValue } from 'recoil';
import type { AtomEffect } from 'recoil';
import { BroadcastChannel } from 'broadcast-channel';
import debounce from 'lodash.debounce';
import mitt from 'mitt';
import type { Emitter } from 'mitt';
import { nanoid } from 'nanoid';
import { equals } from 'ramda';
import { activeWindowListener } from '../modules/activeWindow';
import localForage from 'localforage';
import { logger } from '../modules/logger';

/**
 * Anytime the atom state is updated sends a message to the other
 * atom instances on different windows and synchronizes its state.
 *
 * # Options
 *
 * > Only use these on local development, as they can themselves affect the
 * > overall performance of the synchronization process.
 *
 * ## FPS Reporter
 *
 * Since we don't have a real game loop to measure the fps, this actually measures the number of
 * messages received within a second. It means you need to spam many scroll events in order to
 * saturate the counter to the actual upper limit supported by the environment.
 *
 * - fpsReporter: boolean, if true, will log the fps in the developer tools console
 *
 * ## Delay Reporter
 *
 * This is a simple way to measure the delay between the time the message is sent and the time
 * it is received.
 *
 * - delayReporter: boolean, if true, will log the delay in the developer tools console
 * - delayThreshold: number, if fpsReporter is true, defines the threshold above which the delay will be logged
 *
 * ## Merge Function
 *
 * There are times where multiple pages might be simultaneously updating the Recoil state
 * with different values. In these cases, we can provide a merge function to provide a unified result.
 *
 * -- mergeFn: How to combine old and new values. Defaults to returning the new value unchanged.
 */

type Conductor = 'viewer' | 'reporter' | 'worklist';
export const CONDUCTORS: { [key: Conductor]: Conductor } = {
  viewer: 'viewer',
  reporter: 'reporter',
  worklist: 'worklist',
};

type Options<T> = {
  fpsReporter?: boolean,
  delayReporter?: boolean,
  delayThreshold?: number,
  unidirectional?: boolean,
  mergeFn?: (oldValue: ?T, newValue: T) => T,
  conductor?: Conductor,
};

export const broadcastChannelSynchronizerEffect =
  <T>(userOptions: ?Options<T>): AtomEffect<T> =>
  ({ node: { key }, setSelf, onSet }) => {
    const options = {
      fpsReporter: false,
      delayReporter: false,
      delayThreshold: 1000 / 20,
      unidirectional: false,
      conductor: null,
      mergeFn: (oldValue: ?T, newValue: T) => newValue,
      ...userOptions,
    };

    const bc = new BroadcastChannel(`broadcastChannelSynchronizerEffect_${key}`);

    const id = nanoid();
    let previousFrameTime = options.fpsReporter || options.delayReporter ? Date.now() : 0;
    let framesCount = 0;
    let value; // This will track the atoms current JSONified value

    // Listen to atom changes from other windows
    bc.onmessage = ({ type, origin, payload, now: then }) => {
      if (origin === id) {
        return;
      }

      if (type === 'set') {
        if (typeof payload !== 'string') return;

        const now = options.fpsReporter || options.delayReporter ? Date.now() : 0;

        if (options.fpsReporter) {
          if (previousFrameTime < now - 1000) {
            console.warn(
              `BroadcastChannelSynchronizerEffect: Atom with key "${key}" is updating at ${framesCount} FPS`
            );
            previousFrameTime = now;
            framesCount = 0;
          } else {
            framesCount += 1;
          }
        }

        if (options.delayReporter) {
          const delay = now - then;
          if (delay > options.delayThreshold) {
            console.warn(
              `BroadcastChannelSynchronizerEffect: Atom with key "${key}" was updated ${delay}ms later.`
            );
          }
        }

        // Recoil specifically ignores atom value changes from onSet() if they look like they were set via
        // a setSelf() call from the same atom effect. Depending on the batch, this can cause onSet() to not be called
        // when expected. Its best to only invoke setSelf() when it is actually updating the value of the atom.
        const payloadAsJSON = JSON.parse(payload);
        if (!equals(value, payloadAsJSON)) {
          const newValue = options.mergeFn(value, payloadAsJSON);
          value = newValue;
          setSelf(newValue);
        }
      } else if (type === 'ready') {
        if (options.unidirectional && !activeWindowListener.isWindowActive) return;

        if (options.conductor != null && !window.location.href.includes(options.conductor)) return;

        bc.postMessage({
          type: 'set',
          origin: id,
          payload: JSON.stringify(value),
          now: Date.now(),
        });
      }
    };

    // The logic below is called anytime the atom state is set
    onSet((newValue) => {
      if (newValue instanceof DefaultValue) return;
      if (options.unidirectional && !activeWindowListener.isWindowActive) return;
      if (options.conductor != null && !window.location.href.includes(options.conductor)) return;

      const now = options.fpsReporter || options.delayReporter ? Date.now() : 0;
      if (options.fpsReporter) {
        if (previousFrameTime < now - 1000) {
          console.warn(
            `BroadcastChannelSynchronizerEffect: Atom with key "${key}" is updating at ${framesCount} FPS`
          );
          previousFrameTime = now;
          framesCount = 0;
        } else {
          framesCount += 1;
        }
      }

      value = newValue;
      bc.postMessage({
        type: 'set',
        origin: id,
        payload: JSON.stringify(newValue),
        now: Date.now(),
      });
    });

    bc.postMessage({ type: 'ready', origin: id, now: Date.now() });

    // Close the BroadcastChannel when the atom is destroyed
    return () => {
      bc.close();
    };
  };

type LocalStorageSynchronizerEffectOptions = {
  // Only use integers, always increment by 1, useful if schema changes in non-backawrd compatible way
  version: number,
};

export const makeStoragePersisterEffectKey = (key: string, version: number = 1): string =>
  `localStoragePersisterEffect_${key}_v${version}`;

// The following logic will remove stale localStoragePersisterEffect objects
// This logic runs once per session
export function cleanupStaleLocalStoragePersisterObjects() {
  const keys = Object.keys(localStorage).filter((key) =>
    key.startsWith('localStoragePersisterEffect_')
  );

  keys.forEach((key) => {
    try {
      const raw = localStorage.getItem(key);
      const json = raw != null ? JSON.parse(raw) : null;

      // Delete keys that have not been updated for the past ~month
      if (json?.updated != null && json.updated < Date.now() - 1000 * 60 * 60 * 24 * 30) {
        localStorage.removeItem(key);
      }
    } catch (error) {
      logger.warn('Failed to cleanup stale Local Storage Persister object', error);
    }
  });
}
cleanupStaleLocalStoragePersisterObjects();

/**
 * Legacy, prefer `localForagePersisterEffect` instead for new atoms
 *
 * This effect persist the atom state in localStorage but does not synchronize it
 * if you need to synchronize the state, use the broadcastChannelSynchronizerEffect
 * in combination with this one.
 * The persisted state is read from localStorage on every page load and is written to
 * localStorage on every atom state change with a debounce to avoid too many write accesses.
 */
export const localStoragePersisterEffect =
  <T>({ version }: LocalStorageSynchronizerEffectOptions = { version: 1 }): AtomEffect<T> =>
  ({ node: { key }, setSelf, onSet }) => {
    const storageKey = makeStoragePersisterEffectKey(key, version);

    // Remove the previous version, we assume we always increment versions by 1
    const previousStorageKey = makeStoragePersisterEffectKey(key, version - 1);
    localStorage.removeItem(previousStorageKey);

    const savedValue = window.localStorage.getItem(storageKey);

    if (savedValue != null) {
      // We do try...catch because in case the local storage has some invalid content
      // we don't want to crash the app but simply discard the data.
      try {
        setSelf(JSON.parse(savedValue));
      } catch (err) {
        console.error(err);
      }
    }

    const debouncedSetHandler = debounce(
      (newValue: T, _: mixed, isReset: boolean) => {
        const stringifiedValue = JSON.stringify(newValue);
        if (isReset || stringifiedValue === undefined) {
          try {
            localStorage.removeItem(storageKey);
          } catch (e) {
            console.error(e);
            //analytics not intialized
          }
        } else {
          try {
            localStorage.setItem(storageKey, stringifiedValue);
          } catch (e) {
            console.error(e);
            //analytics not intialized
          }
        }
      },
      1000,
      { leading: true }
    );

    // The logic below is called anytime the atom state is set
    onSet(debouncedSetHandler);
  };

type LocalForageSynchronizerEffectOptions = {
  // Only use integers, always increment by 1, useful if schema changes in non-backawrd compatible way
  version: number,
  // IMPORTANT: new atoms should not use this option, it's here just for legacy compatibility.
  // Disables the effect, this is used just to simplify the migration of existing atoms using `localStoragePersisterEffect`
  // to `localForagePersisterEffect` without having to drastically change their test suites.
  __LEGACY_MIGRATION_DO_NOT_USE__disabled?: boolean,
};

// This effect persist the atom state in localForage but does not synchronize it
// if you need to synchronize the state, use the broadcastChannelSynchronizerEffect
// in combination with this one.
// The persisted state is read from localForage on every page load and is written to
// localForage on every atom state change with a debounce to avoid too many write accesses.
export const localForagePersisterEffect =
  <T>(
    {
      version,
      __LEGACY_MIGRATION_DO_NOT_USE__disabled: disabled,
    }: LocalForageSynchronizerEffectOptions = { version: 1 }
  ): AtomEffect<T> =>
  ({ node: { key }, setSelf, onSet }) => {
    if (disabled === true) return;

    const storageKey = makeStoragePersisterEffectKey(key, version);

    // Remove the previous version, we assume we always increment versions by 1
    const previousStorageKey = makeStoragePersisterEffectKey(key, version - 1);
    localStorage.removeItem(previousStorageKey);

    const savedValuePromise = new Promise(async (resolve) => {
      const value = await localForage.getItem<{ value: T }>(storageKey);

      if (value == null) {
        resolve(new DefaultValue());
      } else {
        resolve(value.value);
      }
    });

    // We do try...catch because in case the local storage has some invalid content
    // we don't want to crash the app but simply discard the data.
    try {
      setSelf(savedValuePromise);
    } catch (err) {
      console.error(err);
    }

    const debouncedSetHandler = debounce(
      (newValue: T, _: mixed, isReset: boolean) => {
        if (isReset || newValue === undefined) {
          localForage.removeItem(storageKey);
        } else {
          // We need to wrap the value in an object because localForage will
          // return `null` if the key is not found, and Recoil will interpret
          // `null` as the actual value, by wrapping it in an object we can
          // differentiate between `null` and "not present".
          localForage.setItem(storageKey, { value: newValue });
        }
      },
      1000,
      { leading: true }
    );

    // The logic below is called anytime the atom state is set
    onSet(debouncedSetHandler);
  };

/**
 * Takes a ref-line object and synchronizes it with the atom state.
 * Useful to access the atom from outside the React lifecycle.
 *
 * @deprecated don't use it! It's here for legacy compatibility.
 */
export const copyToRefEmitter: Emitter<string, mixed> = mitt();
export const copyToRefEffect =
  <T>(ref: { current: T }): AtomEffect<T> =>
  ({ node: { key }, setSelf, onSet }) => {
    copyToRefEmitter.on(key, (newValue) => {
      setSelf(newValue);
      ref.current = newValue;
    });
    onSet((newValue) => {
      ref.current = newValue;
    });
  };
