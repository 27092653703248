// @flow
import { channel } from './workerAnalytics';
import { Analytics } from './analytics';
import { getPage } from 'hooks/useOpenTabs';
export { broadcastChannelAnalytics } from './broadcastChannelAnalytics';

const analytics: Analytics = new Analytics();

// This listens for messages from the channel used by Shared Workers
// to communicate with the main thread and allows to consume
// the analytics API from the worker
channel.addEventListener('message', (event) => {
  const currentPage = getPage();
  const targetPage = event.target;

  if (currentPage.type === targetPage.type && currentPage.windowId === targetPage.windowId) {
    switch (event.type) {
      case 'addContext': {
        analytics.addContext(event.key, event.value);
        break;
      }
      case 'track': {
        analytics.track(event.name, event.data);
        break;
      }
      default: {
        throw new Error(`Unhandled message type: ${event.type}`);
      }
    }
  }
});

export default analytics;
