// @flow

import { SynchronousCachePersistor } from 'apollo3-cache-persist';
import { env } from 'config/env';
const SCHEMA_VERSION_KEY = 'apollo-schema-version';
const CACHE_KEY = 'apollo-cache-persist';

interface LocalForageInterface {
  getItem(key: string): Promise<string | null>;
  setItem(key: string, value: string | { ... } | null): Promise<string | { ... } | null>;
  removeItem(key: string): Promise<void>;
}

type PersistCacheSyncArguments = {
  storage: LocalForageInterface,
  cache: mixed,
  serialize: boolean,
  ...
};

const GRAPHQL_SCHEMA_VERSION = env.GRAPHQL_SCHEMA_VERSION ?? '1';

export const persistCacheSync = ({ storage, cache, ...options }: PersistCacheSyncArguments) => {
  const persistor = new SynchronousCachePersistor({ ...options, cache, storage, key: CACHE_KEY });

  // Remove old localStorage cache, we used it before we moved to localforage, the reason was that
  // localstorage has a quite small limit of ~5MB, which we quickly reached
  localStorage.removeItem(CACHE_KEY);

  try {
    // Version Key needs to be synchronous and stored in local storage;
    const currentSchemaVersion = localStorage.getItem(SCHEMA_VERSION_KEY);
    if (currentSchemaVersion === GRAPHQL_SCHEMA_VERSION) {
      persistor.restoreSync();
    } else {
      persistor.purge();
      localStorage.setItem(SCHEMA_VERSION_KEY, env.GRAPHQL_SCHEMA_VERSION ?? 'undefined');
    }
  } catch (err) {
    // We can expect an error to occurr if the user is browsing in private mode
    // or if they maxed out the available storage

    // eslint-disable-next-line no-console
    console.log(err);
  }
};
