// @flow
import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';

// $FlowFixMe[untyped-import]
import { pathToRegexp } from 'path-to-regexp';

import { PATH, NOT_FOUND_CASE_ID } from 'config/constants';

export const extractWorklistIds = (path: string): string[] => {
  const viewerKeys = [];
  const viewerRegexp = pathToRegexp(PATH.VIEWER, viewerKeys);
  const viewerMatch = viewerRegexp.exec(path);

  const viewerId = viewerMatch?.[viewerKeys.findIndex((k) => k.name === 'worklistId') + 1];

  const reporterKeys = [];
  const reporterRegexp = pathToRegexp(PATH.REPORTER, reporterKeys, { end: false });
  const reporterMatch = reporterRegexp.exec(path);
  const reporterId = reporterMatch?.[reporterKeys.findIndex((k) => k.name === 'worklistId') + 1];
  const id = viewerId ?? reporterId;
  return id != null && id !== NOT_FOUND_CASE_ID ? [id] : [];
};

export const useWorklistId = (): ?string => {
  const matchViewer = useMatch(PATH.VIEWER);
  const matchReporter = useMatch(PATH.REPORTER);
  const worklistId = matchViewer?.params.worklistId ?? matchReporter?.params.worklistId ?? '';

  const smid = useMemo(() => {
    return worklistId.length > 0 && worklistId !== 'undefined' ? worklistId : null;
  }, [worklistId]);

  return smid;
};

export default useWorklistId;
